.trip-details-container {
  width: 100%;
  overflow-x: hidden;
}

.trip-details-tab-toggle-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index:10;
  cursor: pointer;
}
.trip-details-tab-toggle-btn:hover {
  color: $blueGrey;
}

.trip-details-map-box {
  height: 500px;
}
.expended-map-box {
  height: calc(100vh - 155px);
}
.trip-details-tab-container {
  position: relative;
  .ui-tabview-nav {
    width: 100vw;
  }
}
.collapsed-tab-container {
  height: 50px;
  overflow: hidden;
}

.trip-loading-info-container {
  margin-top: 200px;
  font-size: 2rem;
}

.trip-list-container {
  table.summary-table tbody .active-trip {
    background: repeating-linear-gradient(45deg, rgb(141, 255, 160) 0, rgb(141, 255, 160) 20px, rgb(193, 255, 204) 20px, rgb(193, 255, 204) 40px);
    background-size: 56px 56px;
    -webkit-animation: slide 20s infinite linear forwards;
    -moz-animation: slide 20s infinite linear forwards;
    animation: slide 20s infinite linear forwards;
  }
}


@-webkit-keyframes slide {
  0% { background-position: 0% 0; }
  100% { background-position: 100% 0; }
}
@-moz-keyframes slide {
  0% { background-position: 0% 0; }
  100% { background-position: 100% 0; }
}
@keyframes slide {
  0% { background-position: 0% 0; }
  100% { background-position: 100% 0; }
}

.trip-detail-map-container {
  min-height: 500px;
  .parking-icon {
    padding: 4px 7px;
    margin: 0 10px 0 0;
    background: #00bce2;
    border-radius: 15px;
    color: white;
  }
  .parking-instruction {
    margin: 2px 0 2px 8px;
  }
  .trip-details-map-switch {
    position: absolute;
    bottom: 12px;
    left: 12px;
    padding: 10px;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 3px #CCC;
    span {
      margin-right: 20px;
      .ui-inputswitch {
        margin: 0 10px;
      }
    }

  }
}
.trip-details-title {
  position: absolute;
  top: 10px;
  left: 200px;
  background: #FAFAFA;
  border-radius: 2px;
  box-shadow: 0 0 3px #CCC;

  .trip-details-title-span {
    display: inline-block;
    padding: 10px 20px;
    font-size: 0.9rem;
    i {
      color: #00bce2;
      margin-right: 10px;
    }
    .info-element {
      display: inline-block;
      padding: 0;
      margin-left: 10px;
      vertical-align: text-top;
      height: 25px;
      span {
        font-size: 0.9rem;
        padding: 0;
      }
    }
  }
  span:not(:last-child) {
    border-right: 1px solid #EEE;
  }
  .controller {
    padding: 10px;
    cursor: pointer;
    i {
      color: black;
      margin-right: 0;
    }
  }
  .controller:hover i {
    color: #00bce2;
  }
}
.trip-details-panel-box {
  position: absolute;
  top: 80px;
  right: 10px;
  box-shadow: 0 0 5px #000;
  border-radius: 2px;
  .ui-card-body {
    padding: 1em 1em 0;
  }
  .ui-widget-content {
    border: none;
  }
  .trip-details-panel-container {
    background: #000000;
    color: #FFFFFF;
    .trip-details-panel-content {
      margin-top: 20px;
      width: 360px;
      .compass-contianer {
        position: relative;
        text-align: right;
        .compass-bg {
          width: 100px;
          height: 100px;
          margin-right: 44px;
          float: right;
          background-image: url('~assets/icons/compass.png');
          background-size: contain;
        }
        .compass-pin {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 20px;
          right: 64px;
          background-image: url('~assets/icons/compassPointer.png');
          background-size: contain;
        }
      }
    }
    #speedCanvas {
      width: 100%;
      height: 200px;
    }
    .trip-status-container {
      border-top: 2px solid #777777;
      margin-top: 5px;
      padding-top: 12px;
    }
    .trip-details-panel-icon {
      font-size: 1.6em;
      color: #00b5e2;
      margin: auto;
    }
    .trip-details-battery-icon {
      background-image: url('~assets/icons/battery.svg');
      background-size: contain;
    }
    .trip-details-fuel-icon {
      background-image: url('~assets/icons/fuel.svg');
      background-size: contain;
    }
    .trip-details-engine-on-icon {
      background-image: url('~assets/icons/ignitionon.svg');
      background-size: contain;
    }
    .trip-details-engine-off-icon {
      background-image: url('~assets/icons/ignitionoff.svg');
      background-size: contain;
    }
    .trip-details-door-open-icon {
      background-image: url('~assets/icons/doorOn.svg');
      background-size: contain;
    }
    .trip-details-door-closed-icon {
      background-image: url('~assets/icons/doorOff.svg');
      background-size: contain;
    }
    .green {
      color: #00CC74;
    }
    .evo-color {
      color:  #00b5e2;
    }
    .grey {
      color: #777777;
    }
    .ui-g-12, .ui-g-6 {
      padding: 0;
    }
  }
  .info-element span.value {
    color: white;
  }

  .close-btn:hover {
    cursor: pointer;
    color: #0084E9;
  }
}
.trip-details-listing-container {
  .trip-details-list-tr:hover {
    background: #CCCCCC;
    cursor: pointer;
  }
  table.summary-table tbody .selected-mark {
    background-color: #F7A801;
  }
}

#tripDetailsMap {
  width: 100%;
  height: 420px;
}
.trip-detail-list {
  height: calc(100vh - 675px);
  position: relative;
  .summary-table {
    overflow-x:hidden;
  }
  .ui-scrollpanel-content {
    overflow-x: hidden;
  }
  .fixed-body {
    margin-top: 26px;
  }
  .fixed-thead {
    width: 100vw;
  }
  .trip-detail-list-header {
    position: fixed;
    display: inline-table;
    width: calc(100vw - 30px);
    background: white;
    z-index: 1;
  }
  .trip-detail-list-body {
    display: inline-table;
    width: 100vw;
    margin-top: 30px;
    z-index: 0;
    .success {
      color: green;
    }
    .error {
      color: red;
    }
  }
  .td-15 { width: 15%; }
  .td-10 { width: 10%; }
  .td-8 { width: 8%; }
  .td-6 { width: 6%; }
  .td-5 { width: 5%; }
}
.trip-details-direction-container {
  padding: 3px;
  .route-direction-form-container {
    background-color: #f2fbfd;
    min-height: 220px;
    .direction-from-input-container {
      margin-bottom: 40px;
    }
    .direction-to-input-container {
      margin-bottom: 10px;
    }
    .buttons-container {
      height: 100%;
      margin-top: 85px;
    }
  }
  .direction-instruction-col {
    padding-top: 0;
  }
}
.direction-instruction-container {
  border: 2px solid #EEEEEE;
  font-size: 1.2rem;
  .direction-instruction-scroll {
    height: 285px;
  }
  .direction-instruction {
    padding: 10px;
    border-bottom: 1px solid #F0F0F0;
    .toward_street, .next-street {
      color: #0084E9;
      font-size: 1.4rem;
    }
    .direction {
      color: #A06666;
    }
    .length {
      color: #666666;
      font-style: italic;
      font-size: 1.4rem;
    }
  }
}
.trip-details-trouble-shoot-container {
  .cmd-container {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 0 20px;
    .cmd-box {
      margin: auto;
    }
    .button {
      min-width: 285px;
    }
    .expert-cmd-container {
      background-color: #f2fbfd;
    }
  }
}
body {
  .trip-end-date-picker {
    height: 400px;
    .ui-datepicker .ui-timepicker>div a span.fa-angle-up {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
    .ui-datepicker .ui-timepicker>div a span.fa-angle-down {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .ui-datepicker-calendar tr td a {
      cursor: pointer;
    }
  }
}

.split-btn-container {
  display: inline-block;
  padding: 0 !important;
  border-radius: 2rem !important;
  margin: 3px !important;
  box-shadow: none !important;
  button {
    text-transform: uppercase;
    font-weight: 700;
    padding: 3px !important;
    background-color: #ff5a00 !important;
  }
  button:hover {
    background-color: #b74304 !important;
  }
  .ui-corner-left {
    min-width: 235px;
    border-radius: 2rem 0 0 2rem;
    height: 100%;
    padding: 3px;
    text-align: right;
    span {
      margin-right: 60px;
    }
  }
  .ui-corner-right {
    min-width: 50px;
    border-radius: 0 2rem 2rem 0 !important;
    height: 100% !important;
      span {
        font-family: 'Material Icons', serif;
      }
    }
  div.ui-menu {
    background-color: #ff5a00 !important;
    min-width: 235px;
    border-radius: 2rem;
    padding: 0 !important;
  }
  div.ui-menu .ui-menu-list:first-child:hover a {
    border-radius: 2rem !important;
  }
  div.ui-menu .ui-menuitem-link {
    color: white !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
  }
  div.ui-menu .ui-menuitem-link:hover {
    background-color: #b74304 !important;
  }
}
.active-trip-list-container {
  .summary-table {
    .active-trip-list-item {
      cursor: pointer;
    }
    .active-trip-list-item:hover {
      background-color: $brandGray03;
    }
  }
}
