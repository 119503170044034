.ui-tooltip {
    @include clearfix;
    min-width: 0 !important;
    
    ul {
        display: flex;
        flex-flow: column wrap;
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
        
        li {
            margin: 0;
            padding: 0.5rem 0;
            border-top: 1px solid $brandGray03;
        }
        
        li:first-child {
            padding-top: 0;
            border-top: none;
        }
        
        li:last-child {
            padding-bottom: 0;
        }
    }

    .separator {
        margin-top: 0.7rem;
        margin-bottom: 0.4rem;
        border-style: solid;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
    }

    .ui-tooltip-text {
        @include clearfix;
        float: right;
        padding: 0.5rem;
    }

    &.ui-tooltip-right {
        .ui-tooltip-text {
            @include clearfix;
            float: left;
            padding: 0.5rem;
        }
    }

    &.light {
        .separator {
            border-top: 1px solid $lightGray;
        }
    }

    &.dark {
        .separator {
            border-top: 1px solid $brandWhite;
        }
    }
}

// React tooltip (@todo: consolidate tooltip usage if feasible)
.__react_component_tooltip.type-dark.place-bottom::after {
    border-bottom-width: 8px;
}

.__react_component_tooltip.type-dark.place-bottom::after {
    border-top-width: 8px;
}
