.parking-ticket-search-form {
  width: 80%;
  .ui-inputtext {
    width: 100%;
  }
}
.parking-ticket-result {
  .customer-result-link {
    width: 40%;
    min-width: 600px;
    transition: background 0.6s ease-in;
  }
  .trip-result-link {
    text-decoration: none;
    min-width: 200px;
    transition: background 0.6s ease-in;
  }
  .customer-result-link:hover {
    background: rgb(218, 247, 253);
  }
  .trip-result-link:hover {
    background: rgb(218, 247, 253);
  }
}