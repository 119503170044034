// Edited block state
.change-indicator {
    box-shadow: 0 0.5rem 0.75rem rgba($brandBlack, 0.1), inset 0 0 0 0.1rem $brandGray04;
    transition: box-shadow 0.3s;
    border-radius: 0.2rem;
    border: 0;
    padding-top: 0.5rem;
    margin-top: -0.5rem;

    .action-items-frame {
        padding-bottom: 1rem;
        text-align: center;
        z-index: 3;

        button {
            &.ui-button {
                width: auto;
                height: auto;
                margin: 0.1rem;
                min-width: 0;
                padding: 0 2rem;
    
                .ui-button-text {
                    vertical-align: inherit;
                }
            }
        }
    }
}

// Block header
.block-header {
    margin: 0.5rem 0 1rem;
    line-height: 1.3;
    font-weight: 600;
}

// Block validation warning (on load)
.warning-header {
    background-color: $brandAlert;
    height: 0.3em;
    width: 100%;
    border: none;
}

.error-header {
    background-color: $errorFill;
    text-align: center;
    padding-top: rem(20);
    padding-bottom: rem(20);

    &-text {
        padding-left: rem(5)
    }
}