// General pagination container
.pagination-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;

    & > ul > li {
        border: none;
        background: none;
        outline: 0;

        a {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 600;
            width: 0.8rem;
            outline: 0;
        }

        &:first-child {
            margin-right: 1.5rem;
        }

        &:last-child {
            margin-left: 1.5rem;
        }

        &:hover a {
            color: $brandBlue;
        }

        &.rc-pagination-item,
        &.rc-pagination-prev,
        &.rc-pagination-jump-prev,
        &.rc-pagination-next,
        &.rc-pagination-jump-next {
            background: none;
        }

        &.rc-pagination-item-active {
            outline: 0;
        }

        &.rc-pagination-item-active a {
            color: $brandBlue;
            border-bottom: 2px solid $brandBlue;
        }

        &.rc-pagination-prev a, &.rc-pagination-next a {
            font-size: 1.2rem;
        }

        &.rc-pagination-jump-prev,
        &.rc-pagination-jump-next {
            &::after {
                display: inline-flex;
            }

            &:hover::after {
                content: "\2022\2022\2022";
            }
        }
    }
}

// Paginated listing
.pagination-listing {
    position: relative;
    max-width: 90em;
    margin: 1rem auto;
    float: none;
    padding: 0;

    &:empty {
        display: none;
    }
}

.pagination-pages {
    &:empty {
        display: none;
    }
}

.pagination-and-sorting-container {
    flex-wrap: wrap;
    display: flex;
    max-width: 90rem;
    margin: 0 auto;
    width: 100%;
    
    .pagination-summary-container {
        flex: 1;
        min-width: 25rem;

        .pagination-summary {
            padding: 0.5rem 0;
            font-size: 1rem;
            font-weight: 500;

            span.summary-frame {
                padding: 0 1rem;

                &:first-child {
                    padding-left: 0;
                }
            }

            span.pagination-count {
                padding-left: 1rem;

                &.selected {
                    font-weight: bold;
                }
            }
        }

        &:empty {
            display: none;
        }
    }

    .sorting-container {
        flex: 1;
        min-width: 30rem;
        display: flex;
        justify-content: flex-end;

        div.ui-dropdown {
            background-color: $lightGray3;
            font-size: 0.9rem;

            .ui-dropdown-trigger {
                margin-top: 0.5rem;
            }
        
            label.ui-dropdown-label {
                height: 1.8rem;
                padding-left: 1rem;
                line-height: 2;
                font-size: 0.9rem;
            }
        
            label.ui-dropdown-label, div.ui-dropdown.ui-state-focus label.ui-dropdown-label {
                padding-bottom: 0.5rem;
                text-align: left;
            }
        
            div.ui-dropdown-panel {
                top: auto !important;
            }
        }

        &:empty {
            display: none;
        }
    }
}
