// Scroll bars
::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
}
 
::-webkit-scrollbar-track {
    background: $lightGray3; 
}
 
::-webkit-scrollbar-thumb {
    background: $mediumGray; 
}
