// Flag indication
body .input-field.highlight {
    label:not(.ui-dropdown-label) {
        font-weight: 700;
        color: $brandBlack;
    }

    .ui-dropdown {
        border-color: $lightOrange;
        border-width: 2px;

        .ui-dropdown-trigger .fa {
            color: $lightOrange;
        }
    }
}

.flag-indicator {
    @include svg-image('~assets/icons/warning.svg');
    position: absolute;
    height: 1.3em;
    width: 1.3em;
    filter: invert(68%) sepia(82%) saturate(3110%) hue-rotate(335deg) brightness(99%) contrast(105%); // AKA: #FF7D32

    &-label {
        @include svg-image('~assets/icons/warning.svg');
        height: 1.3em;
        width: 1.3em;
        filter: invert(68%) sepia(82%) saturate(3110%) hue-rotate(335deg) brightness(99%) contrast(105%); // AKA: #FF7D32
        position: relative;
        display: inline-block;
        margin-left: .2rem;
        top: 0;
    }
}

.error-indicator {
    position: relative;
    display: inline-block;
    color: #D93C50;
    margin-left: 0.2rem;
}

.error-tooltip {
    font-size: 0.8rem;
    line-height: 1.1;
    width: 10rem;
}

.input-field-error {
    color: $errorText;
    margin-top: 0.25rem;
    animation: fade 0.2s linear forwards;
    display: block;
    font-size: 0.8rem;
}

// Basic text field
.text-field {
    position: relative;

    .ui-inputtext {
        line-height: 1.8;
    }

    .flag-indicator {
        right: 0em;
        top: -0.25em;
    }
}

// Radio overrides
.radio-group-bi {
    font-size: 0.8rem;
    line-height: 1.5;

    & > div {
        white-space: nowrap;
        width: auto;
        display: flex;

        div {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

// Focus states
body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
    box-shadow: none;
}

// Disabled states
body .ui-state-disabled {
    opacity: 0.4;
    cursor: not-allowed !important;

    & ~ label {
        opacity: 0.4;
    }
}

// General select menu icon
body .ui-dropdown {
    .ui-dropdown-trigger {
        width: 2em;
        height: 2em;
    }

    .ui-dropdown-label {
        font-size: 0.9rem;
        color: $charcoal;
    }

    .ui-dropdown-trigger .fa {
        color: $brandBlue;
        font-size: 1.5em;
    }

    &.ui-state-focus {
        border-bottom-width: 1px;
    }

    &.input-error {
        border-bottom: 2px solid $errorText !important;
    }
}

// Basic input overrides
body .input-field {
    margin-right: 0.5rem;

    label {
        left: 0;
        font-size: 0.8rem;
        white-space: nowrap;
        user-select: none;
        color: $mediumGray;
        line-height: 1.2;
    }

    input {
        width: 100%;
        padding-left: 0;
        font-size: 0.9rem;
        color: $charcoal;
    }

    &.input-text {
        label {
            display: inline-block;
            margin-bottom: 0.2rem;
        }

        .ui-inputtext {
            line-height: 1.8;
        }
    }

    &.input-textarea {
        textarea {
            @extend .font-reset-sans;
            min-height: 1.5em;
            resize: vertical;
            color: $charcoal;
            outline: none;
            border-bottom: 1px solid $lightGray4;

            &:focus {
                border-bottom: 1px solid $primaryColor;
            }

            &.field-error {
                border-bottom: 1px solid $errorText;
            }
        }
    }

    &.input-autocomplete {
        label {
            padding-bottom: 0.4rem;
        }

        .ui-autocomplete-token {
            margin: 0.2rem 0.1rem;
            padding: 0.3rem;
            font-size: 1rem;
            border-radius: 0.1rem;
            color: $brandBlack;
            background-color: $lightGray2;
        }

        .ui-autocomplete-token-icon {
            font-size: 0.8rem;
            line-height: 1.2;
        }

        .ui-autocomplete-text {
            font-weight: normal;
            color: $brandBlack;
            margin-left: 0.7rem;
        }

        &.input-autocomplete-multiple label {
            display: inline-block;
            padding-bottom: 0.4rem;
        }
    }

    &.input-checkbox {
        margin-top: rem(10);
        margin-bottom: rem(10);
        display: flex;
        align-items: center;

        &.input-field {
            // override min-height from input-field for checkbox labels
            min-height: 0;
        }

        label {
            padding-bottom: 0;
        }
    }

    &.input-calendar {
        position: relative;

        label {
            display: inline-block;
            padding-bottom: 0.3rem;
        }

        span.fa {
            height: 1.3rem;
            vertical-align: top;
        }

        .input-field-error {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    &.input-timepicker {
        input {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $lightGray4;
        }
    }

    &.input-typeahead div.Select {
        margin-top: 0.55rem;
    }

    &.select-menu, // @todo: consolidate these & fix specificity issues
    &.input-dropdown {
        .ui-dropdown {
            width: 100% !important;

            &.input-error {
                border-bottom-color: $errorText;
            }
        }

        label {
            display: inline-block;
            padding-bottom: 0.33rem !important;
            padding-left: 0;
        }
    }

    .input-block {
        display: block;
    }

    .radio-option, .checkbox-option {
        display: inline-flex;
        margin: 0.5rem 1rem 0.5rem 0;
        align-items: center;

        &.vertical {
            width: 100%;
        }
    }
}

// Custom switch/toggle
body .ui-switch-toggle {
    &:checked ~ label {
        background: $brandBlue;

        &:after {
            left: 0.8rem;
            background: $brandWhite;
        }
    }

    &:disabled ~ label {
        background: $brandGray03;
        pointer-events: none;

        &:after {
            background: $brandWhite;
        }
    }

    &-container {
        position: absolute;
        top: 1.25rem;
        right: -0.8rem;
        width: rem(44);
        padding: 0;
    }

    &-label {
        position: relative;
        display: block;
        height: rem(18);
        width: rem(30);
        background: $brandGray01;
        border-radius: rem(100);
        cursor: pointer;
        transition: all 0.3s ease;

        &:after {
            position: absolute;
            left: rem(1);
            top: rem(1);
            display: block;
            width: rem(16);
            height: rem(16);
            border-radius: rem(100);
            background: $brandWhite;
            box-shadow: 0 rem(3) rem(3) rgba($brandBlack, 0.05);
            content: '';
            transition: all 0.3s ease;
        }
    }
}

// Custom switch selector
.switch-field {
    .ui-inputtext {
        padding-bottom: 0.3rem !important;
    }
}

// Checkbox overrides
body .ui-chkbox {
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    margin-right: 0.3rem;

    .ui-chkbox-box {
        border: 1px solid $brandGray01;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.3rem;
        height: 1.3rem;

        .ui-chkbox-icon {
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 1rem;
            margin: 0;
            line-height: 1;
        }

        &.ui-state-active {
            background: $brandWhite;
            color: $brandBlue;
            border-color: $brandGray01;

            .ui-chkbox-icon {
                opacity: 1;
            }
        }
    }
}

// Containers
.form-container, .notification-container {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
}

// Notification container
.notification-container {
    animation: fadeInDown 0.6s ease-in-out forwards;

    &:empty {
        display: none;
    }
}

// Common button container
.buttons-container {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;

    & > div {
        display: flex;
        justify-content: center;
    }
}

// Basic select
.select-menu {
    position: relative;

    .ui-dropdown-clear-icon {
        padding: 0 20px 20px 10px;
        top: 0.74rem;
        color: #00bce2;
    }

    .ui-dropdown {
        border-bottom-width: 1px;
    }

    .flag-indicator {
        right: 0.35rem;
        top: -0.1rem;
    }
}

// Multiple select (e.g., date menus)
.multi-select-menu {
    margin-bottom: 1rem;

    label {
        display: inline-block;
        padding-bottom: 0 !important;
        padding-left: 0;
    }

    .ui-dropdown {
        margin: 1rem 0.5rem 0 0;
        border-bottom-width: 1px;
    }
}

// Default textareas
body {
    textarea {
        width: 100%;
        border: none;
        font-size: 1rem;
        border-bottom: 1px solid $brandGray01;
        padding: 0;
    }

    .ui-inputtext {
        max-width: 100%;
    }
}

.text-area-countdown {
    display: inline-block;
    width: 100%;
    text-align: right;
    font-weight: normal;
}

span.action-link {
    white-space: nowrap;

    i {
        margin: auto 0.3rem;
        text-decoration: none;
    }

    span {
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 600;
    }

    &:hover span {
        cursor: pointer;
    }
}

.select-menu-alert {
    z-index: 3;
    background-color: $brandWhite;
}

.action-items-frame {
    white-space: nowrap;
}

.ui-inputtext.input-error {
    border-bottom: 2px solid $errorText !important;
}

// @TODO: Clean up!
form.ui-form {
    .ui-inputtext, .ui-autocomplete, .ui-dropdown-label, .ui-dropdown-item, .ui-autocomplete-input-token > input {
        font-size: 0.9rem !important;
        white-space: nowrap;
    }

    .ui-dropdown-label {
        padding-bottom: 0.3rem;
    }

    .ui-calendar {
        padding-top: rem(6);
    }

    .ui-inputtext, .ui-autocomplete, .ui-dropdown, .ui-calendar {
        width: 100% !important; // @todo: remove the inline styling on these!
    }

    .ui-autocomplete-multiple-container {
        padding: 0 4rem 0 0 !important;
        white-space: pre-wrap;

        &.ui-state-focus {
            margin-bottom: rem(-1);
        }
    }

    .ui-autocomplete {
        padding-top: rem(5);
    }

    .categories-input-field {
        position: relative;
    }
}

.form-error-message-container {
    margin-top: 1rem;
    color: $gray;
    background-color: $errorFill;
    text-align: center;
    font-weight: 700;

    i {
        margin: 0 0.5rem;
        color: $brandError;
    }
}

textarea {
    outline: none;
}