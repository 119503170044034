.home-container {
    min-height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

// Home dashboard tiles
.dashboard {
    background: rgba($brandBlack, 0.75);
    padding: 2.5rem 1rem 1rem;
    max-width: 65%;
    min-width: 45%;
    margin: auto;

    .navigation-links {
        display: flex;
        flex: 1 1 100%;

        & > ul {
            justify-content: center;
            display: flex;
            flex-wrap: wrap;

            & > li {
                margin: 0 2rem 4rem;

                & > a {
                    display: inline-flex;
                    width: 5rem;
                    height: 5rem;
                    background-color: $deepGray;
                    transition: background-color 0.2s;
                    text-decoration: none;

                    & > span {
                        display: block;
                        text-align: center;
                        top: 1.5em;
                        position: relative;
                        align-self: flex-end;
                        color: $brandWhite;
                        font-weight: 600;
                        font-size: 1.5rem;
                        text-transform: uppercase;
                    }

                    &:hover,
                    &.active {
                        background-color: $brandBlue;
                    }
                }
            }
        }
    }

    .tile {
        padding: 0.25rem;

        a {
            text-decoration: none;
        }

        .tile-inner {
            background: rgba($brandBlack, 0.25);
            transition: background 0.2s;
            display: flex;
            align-items: center;
            height: 1.5rem;
            justify-content: center;
            padding: 1rem;
            color: $brandWhite;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            border: 2px solid rgba($brandWhite, 0.9);

            &.placeholder, &.placeholder:hover {
                @extend .placeholder;
                color: $brandWhite;
                border: 2px solid rgba($brandWhite,0.25);
            }

            &:not(.placeholder):hover {
                background: $brandBlue;
                border: 2px solid $brandBlue;
            }
        }
    }

    // @include split-responsive {
    //     width: 100%;
    //     height: 100%;
    // }
}
