@import '../_variables';

$headerPadding: 0.625em 1em;

$contentPadding: 0.625em 1em;
$contentBgColor: #ffffff;
$contentBorderColor: #d8d8d8;
$contentLineHeight: 1;

$inputBorderColor: #bdbdbd;
$inputPadding: 2px 2px 1px 2px;
$inputHeaderPadding: 0.625em 1em;
$inputGroupAddonBgColor: #e6e6e6;
$textboxBgColor: #f7f7f7;
$inputBgColor: #ffffff;

$listItemPadding: 0.625em 1em;

$radioButtonBorderColor: #757575;
$checkboxBorderColor: #757575;

$dataTableBorderColor: #cacaca;
$dataTableFilterInputBgColor: #ffffff;
$dataTableEditableCellInputBorderColor: #ffffff;
$dataTableEditableCellInputColor: #ffffff;
$dataTableRowBgColorEven: #f4f4f4;
$dataTableEvenRowBgColor: #f4f4f4;

$stepsNumberBgColor: #757575;
$stepsNumberColor: #ffffff;

$infoMessageBgColor: #2196F3;
$infoMessageBorderColor: #2196F3;
$infoMessageTextColor: #ffffff;

$warnMessageBgColor: #ffc107;
$warnMessageBorderColor: #ffc107;
$warnMessageTextColor: #ffffff;

$errorMessageBgColor: #e62a10;
$errorMessageBorderColor: #e62a10;
$errorMessageTextColor: #ffffff;

$successMessageBgColor: #8BC34A;
$successMessageBorderColor: #8BC34A;
$successMessageTextColor: #ffffff;

$tooltipBgColor: #323232;

@import '../../base/_variables';
