.input-typeahead {
    .Select {
        margin-top: 0.5rem;
        
        .highlighted-value {
            color: $brandBlack;
            font-size: 0.9rem;
            font-weight: 500;
            
            mark {
                background-color: $highlightFill;
                color: $gray;
                font-weight: bold;
            }
        }
    
        .Select-menu-outer {
            border-radius: 0;
            margin-top: 0;
            font-size: 0.9rem;
        }
    
        .Select-control {
            @extend .font-reset-sans;
            border: 1px solid $lightGray4;
            background: none;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            outline: none;
            min-height: rem(24);
            overflow: visible;
            height: auto;
            box-shadow: none;
            max-width: 100%;
    
            .Select-multi-value-wrapper {
                display: inline-block;
                max-width: 100%;
            }
    
            .input-typeahead-dropdown-icon {
                color: $brandBlue;
                font-size: 1.5rem;
            }
        
            .Select-value {
                margin: 0.1rem 0;
                position: relative;
                font-size: 0.9rem;
                padding-right: 1rem !important;
                color: $brandBlack;
                border: none;
                background-color: $lightGray2;
                line-height: 2;
                max-width: 100%;
            }
    
            .Select-value-label {
                @include truncate;
                max-width: 100%;
                padding-right: 1.3rem;
            }
            
            .Select-clear-zone, .Select-arrow-zone {
                display: none;
            }
    
            .Select-value-icon, .Select-value-label {
                border: none;
            }
    
            .Select-value-icon, .Select-clear, .Select-value-label {
                color: $brandBlack;
            }
    
            .Select-value-icon, .Select-clear {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-weight: bold;
                font-size: 1.2rem;
            }
        
            .Select-input {
                padding: 0 0.2rem;
                margin-left: 0;
                min-width: 2rem;
                outline: none;
                height: rem(23);

                & > input {
                    padding: 0.2rem 0;
                }
            }
        }
    
        .Select-option {
            @extend .font-reset-sans;
            @extend .highlighted-value;
            margin: 0;
            padding: 0.6rem;
            border-bottom: 1px solid $lightGray2;
            font-size: 0.9rem;
            font-weight: 500;
            color: $brandBlack;
        }
    
        .Select-option:last-child {
            border-bottom: none;
        }
    
        &.is-focused:not(.is-open) > .Select-control {
            box-shadow: none;
            background: $highlightFill;
            border-color: $brandBlack;
        }
    }
}
