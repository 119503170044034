.alert-container {
    display: inline-flex;
    flex: 1;
    padding: 1.2rem 1rem 1rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $brandAlertVariant;
    justify-content: flex-end;
    text-align: right;
    color: $gray;
    height: 3.1rem;
    min-width: 4rem;
    box-sizing: border-box;
    align-items: center;
    z-index: 5;
    max-width: 60%;

    svg {
        margin-right: 0.3rem;
        text-decoration: none;
    }

    .alert-message {
        display: block;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 2rem;
        padding: 0 1rem 0 0;
        color: $gray;
        font-size: 0.85rem;
        line-height: 2.4;

        i {
            color: $lightOrange;
            flex: 0 0 1rem;
            padding-right: 0.5rem;
        }

        span {
            @include truncate;
            flex: 1;
        }
    }

    @include split-responsive {
        top: 3.2rem;
        max-width: none;
        left: 0;
        justify-content: center;
        text-align: center;
    }
}

.alert-summary {
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: $brandAlertVariant;
    font-size: 0.85rem;

    & > p {
        margin-top: 0;
    }

    & > b {
        font-size: 0.85rem;
    }
}

#add-alert-link, #edit-alert-link {
    font-weight: 700;
    font-size: 0.85rem;
    text-transform: uppercase;
    text-decoration: none;
}

#add-alert-link {
    color: $orangeDark;
}

#alert-modal {
    .icons-container {
        display: flex;
        justify-content: flex-end;

        & > div {
            color: $deepGray;
            padding-left: 0;
            white-space: nowrap;
        }
    }
}