.parking-lot-table {
  th {
    border: none !important;

    span {
      margin: 0;
      color: #777;
      font-size: .9rem;
      font-weight: 600;
    }
  }

  td {
    border: none !important;
  }

  .ui-datatable-thead {
    .fa {
      font-family: "Material Icons", serif;
    }
  }
}

.parking-lot-form {
  .input-field {
    margin-bottom: 10px;
  }

  .input-checkbox {
    width: 5%;
  }

  .input-checkbox-10 {
    width: 10%;
  }
}

.parking-lot-col-15 {
  width: 15% !important;
  text-align: left !important;
}

.parking-lot-col10 {
  width: 10% !important;
  text-align: center !important;
}

.parking-lot-col8 {
  width: 8% !important;
  text-align: center !important;
}

.parking-lot-date {
  text-align: center !important;
}
