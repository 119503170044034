// General app vars
$brandBlue:                 #00BCE2;
$brandBlueVariant:          #F2FBFD;
$brandBlack:                #000000;
$brandWhite:                #ffffff;
$brandGray01:               #838383;
$brandGray02:               #9B9B9B;
$brandGray03:               #D1D1D1;
$brandGray04:               #E7E7E7;
$brandGray05:               #F6F6F6;
$brandComplete:             #7ED321;
$brandCompleteVariant:      #DAF3BF;
$brandError:                #D0021B;
$brandErrorVariant:         #FFEBED;
$brandAlert:                #FF7D32;
$brandAlertVariant:         #FFDCC8;

$lightGray:                 #D3D3D3;
$mediumGray:                #838383;
$deepGray:                  #777777;
$errorText:                 #D0021B;
$errorFill:                 #FBEAED;
$lightestGray:              #f6f6f6;
$lightGray2:                #F1F1F1;
$lightGray3:                #E7E7E7;
$lightGray4:                #BDBDBD;
$lightGray5:                #E6E6E6;
$gray:                      #4C4C4C;
$charcoal:                  #202020;
$actionColor:               #50bcd8;
$lightSkyBlueColor:         #F2FBFD;
$tooltipGray:               #606060;
$highlightFill:             #E5F8FC;

$em-base: 16;

// App fonts
$fontCustom:                "Pero", Arial, Helvetica, sans-serif;
$fontSans:                  "Source Sans Pro", "Helvetica Neue", sans-serif;
$fontIcons:                 "custom-icons";

// Responsive setup
$breakpoints: ('small': 20em, 'medium': 40.063em, 'large': 64.063em, 'x-large': 90.063em);

// Prime React
$textColor: #212121;
$textSecondaryColor: #757575;
$fontFamily: $fontSans;
$fontSize: 1rem;
$lineHeight: 1;
$transitionDuration: 0.3s;
$borderRadius: 0.2rem;
$iconFontSize: 1.5em;
$iconWidth: 1em;
$iconHeight: 1em;
$overlayMaskBgColor:#424242;
$errorColor: #e62a10;
$errorTextColor: #ffffff;
$hoverBgColor: #e8e8e8;
$hoverTextColor: #000000;
$dividerColor: #dbdbdb;
$dividerLightColor: #cacaca;
$headerPadding: 0.85rem 0;

$blueGrey: #607D8B;
$cyan: #00BCD4;
$teal: #009688;
$red: #F44336;
$green: #4CAF50;
$deepOrange: #FF5722; 
$lightOrange: #FF7D32; 
$purple: #673AB7;
$pink: #E91E63;
$amber: #FFC107;
$orange: #FF9800;
$brown: #795548;
$indigo: #3F51B5;

$blueGreyDark: #37474F;
$cyanDark: #00838F;
$tealDark: #009688;
$redDark: #C62828;
$greenDark: #2E7D32;
$deepOrangeDark: #D84315;
$purpleDark: #4527A0;
$pinkDark: #AD1457;
$amberDark: #FF8F00;
$orangeDark: #EF6C00;
$brownDark: #4E342E;
$indigoDark: #283593;

// Ultima (Prime) Layout (Blue-Dark)
$primaryColor: #3e464c;
$primaryDarkColor: #2b3135;
$primaryLightColor: #777d81;
$accentColor: #00bce2;
$accentDarkColor: #274d8d;
$accentLightColor: #86b0f5;
$accentTextColor: #ffffff;
$darkMenuBgColor: #2b3135;
$darkMenuHoverColor: #777d81;
$darkMenuRouterLinkActiveColor: #00bce2;
$lightMenuRouterLinkActiveColor: #00bce2;
$horizontalLightMenuRouterLinkActiveColor: #00bce2;

// Ultima (Prime) Theming (Blue-Dark)
$primaryColor: #3e464c;
$primaryDarkColor: #2b3135;
$primaryLightColor: #777d81;
$primaryTextColor: #ffffff;
$accentColor: #00bce2;
$accentDarkColor: #274d8d;
$accentLightColor: #86b0f5;
$accentTextColor: #ffffff;


//Other Colors
$lightBrilliantOrange: #ffb336;
$veryPaleOrange: #ffe8c2;