.header-container {
    display: block;
    background: $brandBlack;
    align-content: stretch;

    .breadcrumbs-container {
        display: flex;
        padding: 0 2rem;
        flex-flow: row nowrap;
        align-items: stretch;

        .breadcrumbs {
            flex: 1;
            padding: 0.6rem 0.5rem 0.8rem;
            border-left: 1px solid $deepGray;
            font-size: 0.9rem;
            font-weight: normal;
            flex-basis: 50%;
            box-sizing: border-box;

            a {
                text-decoration: underline;
            }

            a,
            span.separator {
                color: $brandWhite;
            }

            span.title {
                color: $brandBlue;
                text-decoration: none;
            }
        }

        .breadcrumbs:first-child {
            border-left: none;
            padding-left: 0;
        }
    }
}

.navigation-container {
    padding: 1.5rem 2rem 0;
    align-items: stretch;

    .navigation {
        flex: 1;
        display: flex;
        height: 1.5rem;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 2px solid $brandBlue;
    }

    .evo-logo {
        flex: 0 0 5rem;
        padding: 0 1rem;
        align-items: stretch;

        i {
            @include svg-image('~assets/logo.svg');
            flex: 1;
            display: block;
            height: 3rem;
        }
    }

    .user-info {
        flex: 1;
        padding: 0 0.5rem;
        font-family: $fontCustom;

        span {
            font-size: 0.9rem;
            font-weight: 700;
        }

        .authentication {
            display: inline-flex;
            padding-right: 0.2rem;
            min-width: 7.5rem;
            align-items: center;
            margin: 0.6rem 0 0.6rem 0.2rem;

            span {
                &.user-initials {
                    background-color: $brandBlue;
                    border-radius: 50%;
                    width: 2.1rem;
                    height: 2.1rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: $brandBlack;
                    text-transform: uppercase;

                    ul {
                        text-transform: none;
                    }
                }

                &.user-name {
                    margin-left: 0.6rem;
                    color: $brandBlue;
                }
            }
        }
    }

    .split-screen-btn {
        flex: 0 0 2rem;
        padding: 0.3rem 0 0.3rem 1rem;
        border-left: 1px solid $gray;

        i {
            display: inline-flex;
            background: $brandGray01;
            position: relative;
            width: 2.25rem;
            height: 1.5rem;
            transition: background-color 0.2s;
            cursor: pointer;

            &.split-screen-open, &:hover {
                background: $brandBlue;
            }

            &::after {
                position: absolute;
                content: '';
                background-color: $brandBlack;
                width: calc(50% - 0.1rem);
                top: 0.05rem;
                left: 0.05rem;
                height: calc(100% - 0.1rem);
            }
        }
    }

    .switch-locale-btn {
        display: none;
        flex: 0 0 1.7rem;
        text-transform: uppercase;
        align-items: center;

        i {
            flex: 1;
            font-size: 0.9rem;
            font-style: normal;
            text-align: center;
            line-height: 1.7rem;
            color: $deepGray;
            border: 1px solid $deepGray;

            &:hover {
                cursor: pointer;
                border: 1px solid $brandWhite;
                color: $brandWhite;
            }
        }
    }
}

// Shared navigation links
.navigation-links {
    display: flex;

    & > ul {
        margin: 0;
        padding: 0;
        flex: 1;
        height: 100%;
        list-style: none;

        & > li {
            display: inline-flex;
            margin: 0 0.5rem;

            & > a, & > button {
                display: inline-flex;
                background-color: $gray;
                border-radius: 50%;
                width: 2.1rem;
                height: 2.1rem;
                transition: background-color 0.2s;
                text-align: center;
                justify-content: center;

                & > span {
                    display: none;
                }

                &:hover,
                &.active {
                    background-color: $brandBlue;
                }
            }

            &.promotions > a, &.promotions > button {
                @include svg-image('~assets/icons/management.svg', 95%);
            }

            &.tickets > a, &.tickets > button {
                @include svg-image('~assets/icons/ticket.svg', 95%);
            }

            &.customers > a, &.customers > button {
                @include svg-image('~assets/icons/profile.svg', 90%);
            }

            &.trips > a, &.trips > button {
                @include svg-image('~assets/icons/trip.svg', 60%);
            }

            &.more > a, &.more > button {
                @include svg-image('~assets/icons/more.svg', 89.25%);
            }
            &.cars > a, &.cars > button {
                @include svg-image('~assets/icons/car.svg', 70%);
            }
        }
    }
}

// DD Menus
.floating-menu-container {
    position: relative;
    padding: 0.75rem 0;

    ul.floating-menu {
        z-index: 10;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        padding: 0 0 0.75rem;
        margin-top: 0.7rem;
        transform: translateY(-0.5em);
        transition: 0.3s ease-in-out;
        left: -2.2rem;
        top: 2.75rem;
        min-width: 8rem;
        background-color: $brandBlue;
        box-shadow: 0 1px 1px rgba($brandBlack, 0.2);

        & > li {
            padding: 1rem 1rem 0;
            white-space: nowrap;

            a,
            button,
            span {
                font-family: $fontCustom;
                color: $brandWhite;
                display: block;
                font-size: 1rem;
                font-weight: normal;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:only-child {
                padding: 0.75rem 1rem 0;
            }
        }

        &:before {
            content: '';
            position: absolute;
            border: 1px solid $brandBlue;
            border-color: $brandBlue transparent;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            top: -0.5rem;
            left: 2.75rem;
        }
    }

    &:hover {
        a, button {
            background-color: $brandBlue !important;
        }

        .floating-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}
