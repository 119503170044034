// Customer profile styles
.label-hint {
    width: 100%;
    display: inline-block;
}

.label-value {
    width: 100%;
    display: inline-block;
    line-height: 2.2em;
    cursor: default;
}

.account-status-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($brandWhite, 0.6);
    z-index: 2;
}

.customer-profile-view {
    .document-frame {
        @extend .layout-container;

        .input-field {
            min-height: 4.5rem;

            label, .label-value {
                @include truncate;
                font-size: 0.85rem;
                padding-bottom: 0.1rem;
            }
        }

        .account-summary {
            background-color: $lightSkyBlueColor;
            margin-top: 0.375em;
            margin-bottom: 1.5rem;
            padding-left: 1em;
            padding-top: 0.5em;
            flex: 2;

            .action-items-frame {
                position: absolute;
                top: 0;
                right: 0.5rem;
                padding: 0.5em 0;
            }

            .input-group {
                display: flex;
                // flex-direction: row;
                flex-flow: row wrap;
                justify-content: space-between;

                @include split-responsive { // specific fields inferred here ¯\_(ツ)_/¯
                    & > div:nth-child(1) {
                        order: 1;
                    }

                    & > div:nth-child(2) {
                        order: 2;
                    }

                    & > div:nth-child(3) {
                        order: 3;
                    }

                    & > div:nth-child(4) {
                        order: 4;
                    }

                    & > div:nth-child(5) {
                        order: 9;
                    }

                    & > div:nth-child(6) {
                        order: 10;
                    }

                    & > div:nth-child(7) {
                        order: 11;
                    }

                    & > div:nth-child(8) {
                        order: 16;
                    }

                    & > div:nth-child(9) {
                        order: 5;
                    }

                    & > div:nth-child(10) {
                        order: 6;
                    }

                    & > div:nth-child(11) {
                        order: 7;
                    }

                    & > div:nth-child(12) {
                        order: 8;
                    }

                    & > div:nth-child(13) {
                        order: 12;
                    }

                    & > div:nth-child(14) {
                        order: 13;
                    }

                    & > div:nth-child(15) {
                        order: 14;
                    }

                    & > div:nth-child(16) {
                        order: 15;
                    }

                    & > div:nth-child(17) {
                        order: 17;
                    }
                }
            }

            .input-field {
                flex: 1;
                min-width: calc(11.1% - 1rem);
                margin-right: 1rem;
            }

            .input-email {
                min-width: calc(22.2% - 1rem);
                flex: 2;
            }

            .ui-inputswitch {
                position: absolute;
                right: 0.5em;
                top: 50%;
                transform: translateY(-50%);
            }

            .ui-progress-spinner {
                width: 3rem;
                height: 3rem;
            }

            @include split-responsive {
                .input-field {
                    flex-basis: calc(22.2% - 1rem);
                }

                .input-email {
                    flex-basis: calc(44.4% - 1rem);
                }
            }

            @include media('<=large') {
                .input-field, .input-email {
                    .split-screen-open & {
                        flex-basis: calc(100% - 1rem);
                    }
                }
            }

            @include media('<=medium') {
                .input-field, .input-email {
                    flex-basis: calc(100% - 1rem);
                }
            }
        }

        .additional-information {
            flex-wrap: wrap;
            display: flex;

            .ui-progress-spinner {
                width: 3rem;
                height: 3rem;
            }

            .license-notes-payment-information,
            .personal-school-membership-information {
                width: 50%;
                flex-wrap: wrap;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                align-content: flex-start;

                @include media('<=large') {
                    .split-screen-open & {
                        width: 100%;
                    }
                }

                @include media('<=medium') {
                    width: 100%;
                }
            }

            .personal-information, .memberships-clubs, .memberships-school, .drivers-license, .notes-payment-information {
                flex: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-left: 1em;
                padding-right: 1em;
                max-width: 50%;
                border-left: solid 1px $brandGray04;

                .radio-group-bi {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    span {
                        line-height: 1.7;
                        white-space: nowrap;
                        // min-width: 180px;
                    }
                }
            }

            .notes-payment-information {
                hr {
                    border: 1px solid $lightGray;
                    margin-bottom: 0;
                    width: 100%;
                }

                .button-tertiary {
                    margin-bottom: 0.5rem;
                }


                .note-cell, .add-note-frame {
                    margin-bottom: 0;
                }

                div.remaining {
                    font-size: 0.8em;
                    text-align: left;
                }

                .scroll-notes {
                    height: 235px;
                    overflow-x: auto;
                }
            }

            & > div {
                min-width: 14rem; // force mid breakpoint wrap
            }

            // Split screen and responsive conditions
            @include split-responsive {
                .personal-information, .memberships-clubs, .memberships-school, .drivers-license, .notes-payment-information {
                    max-width: 100%;
                }

                .personal-information {
                    order: 1;
                }

                .memberships-school {
                    order: 2
                }

                .memberships-clubs {
                    order: 3;
                }
            }
        }

        & > div {
            transition: opacity 0.3s;
        }
    }

    .error-header {
        background-color: $errorFill;
        text-align: center;
        padding-top: rem(20);
        padding-bottom: rem(20);
    }

    & > .ui-tabview {
        & > .ui-tabview-panels { // includes height of header + tabs (keeps tabs fixed on scroll)
            overflow: auto;
            height: calc(100vh - 3rem - 6.5rem);

            @include split-responsive {
                height: calc(100vh - 3rem - 3.2rem - 6.5rem);
            }
        }
    }

    @include split-responsive {
        .ui-tabview-panels {
            margin-top: 3.2rem;
        }
    }
}

// Override for inner change indicator padding on billing information
.payment-information.change-indicator {
    margin-left: -1rem;
    padding-left: 1rem;
}

// Customer Statements Container
.billing-monthly-statements .summary-table td {
  &:nth-child(3) {
    width: 65px;
  }

  &:nth-child(4) {
    width: 10px;
  }
}

// Activity log view
.customer-activity-log {
    .summary-table {
        td {
            word-break: break-all;
        }
    }
}

// Customer suggestion rendering
.customer-suggest-option {
    @extend .highlighted-value;

    .email {
        width: block;
        margin-bottom: 0.8rem;
    }

    .customer-number {
        flex: 1;
    }

    .customer-name {
        flex: 3;
    }
}

.customer-suggest-inner {
    display: flex;
}

// Field widths
.info-customer-search_email {
    flex: 2;
}

.customer-result-link {
    text-decoration: none;
}

.recent-tickets-container {
    min-height: 300px;
}
