.reservation-map-container {
    height: calc(100vh - 12.5rem);
    position: relative;
    box-shadow: 0 0 3px black;
    .reservation-msg-container {
        position: absolute;
        top: 0.4em;
        left: 25%;
        width: 50%;
    }
}
.reservation-map-container {
    .gm-style .gm-style-iw-c {
        overflow: visible !important;
        .gm-style-iw-d {
            overflow: visible !important;
        }
    }
    .button-submit {
        margin-top: 20px;
    }
}
.reserved-map {
    .gm-style-iw .gm-ui-hover-effect {
        display: none !important;
    }
}

.reservation-map-direction-container {
    position: absolute;
    top: 4.2em;
    left: 10px;
}
.address-search-input-container {
    .address-search-input {
        padding: 0.5em;
        background: $brandWhite;
        border-radius: 0.5em;
        box-shadow: 0 0 3px $brandGray01;
        z-index: 1000005;
        .ui-inputtext {
            width: calc(100% - 2.2em);
            min-width: 20em;
        }
        i {
            font-size: 1.5em;
            color: $brandBlue;
            margin: 0 0.2em;
        }

    }
    .is-searching {
        border-radius: 0.5em 0.5em 0 0;
    }
    .address-search-result-list {
        border-radius: 0 0 0.5em 0.5em;
        box-shadow: 0 0 3px $brandGray01;
        background: $brandWhite;
        .address-search-result-item {
            padding: 1em 0.5em;
            width: 100%;
            cursor: pointer;
            border-bottom: 1px solid $brandGray05;
        }
        .address-search-result-item:hover {
            background-color: $brandGray04;
        }
    }
    .address-search-result-list:last-child {
        border: none;
    }
}
.car-info-window {
    margin: 2em;
    width: 20em;
    .reserved-title-car {
        text-align: center;
        margin-top: -4.5em;
        img {
            width: 6.5em;
            height: auto;
        }
    }
    .car-info-window-title {
        text-align: center;
        font-size: 1.2em;
        font-weight: bold !important;
        margin-top: 0.8em;
    }
    .car-info-window-content {
        .car-info-label {
            color: $brandBlue;
            font-weight: bolder;
        }
        .car-info {
            margin: 1em 0;
            min-width: 250px;
            width: 100%;
            text-align: center;
            .car-info-fuel {
                color: $brandBlue;
                font-weight: bolder;
                img {
                    margin: 0 0.5em;
                }
            }
            .car-info-address {
                min-width: 250px;
                width: 100%;
            }
            .car-model {
                margin-right: 4px ;
            }
        }
        .car-info-button {
            text-align: center;
        }
        .divider {
            width: 250px;
            margin: 1em 0;
            border: 1px solid $brandGray05;
        }
        .car-info-ready {
            font-weight: bold;
            margin: 5px 0;
        }
        .car-info-bottom {

            text-align: center;
            background: $brandBlack;
            color: $brandWhite;
            width: 325px;
            margin: 20px 0 -26px -38px;
            padding: 20px 5px;
            border-radius: 0 0 8px 8px;
        }
        .button-reset {
          width: 100%;
        }
    }
}
.reservation-refresh-container {
    font-size: 1.5em;
    position: absolute;
    top: 0.3em;
    left: 8em;
    color: $brandBlue;
    background: $brandWhite;
    padding: 0.5em;
    box-shadow: 0 0 5px $brandGray03;
    border-radius: 0.3em;
    i {
        cursor: pointer;
        transition: transform .8s ease-in-out;
        -webkit-transition: -webkit-transform .8s ease-in-out;
        -ms-transition: -ms-transform .8s ease-in-out;
    }
    i:hover {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}
.reservation-float-info-window {
  position: absolute;
  top: 4em;
  right: 0.7em;
  width: calc(384px + 2em);
  padding: 1em;
  border-radius: 0.5em;
  background: $brandWhite;
  box-shadow: 0 0 3px $brandGray01;
  .car-info-window .car-info-window-content .car-info-bottom {
    width: 416px;
    margin: 20px 0 -50px -48px;
  }
}
