// Common button class
body .button {
    margin: auto 0;
    text-transform: uppercase;
    border-radius: 2rem;
    font-size: 0.9rem;
    line-height: 2.5;
    height: auto;
    font-weight: 700;
    border: none;
    min-width: 10rem;
    white-space: nowrap;
    outline: none;
    padding: 0.3rem 2rem;
    transition: background-color 0.2s linear;

    &.button-primary {
        color: $brandWhite;
        background-color: $brandBlue;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:enabled:not(:focus):hover, &:hover {
            background-color: darken($brandBlue, 10%);
        }
    }

    &.button-secondary {
        background-color: $brandWhite;
        color: $brandBlue;
        border: 1.5px solid $brandBlue;

        &:enabled:not(:focus):hover, &:hover {
            border-color: darken($brandBlue, 15%);
            color: darken($brandBlue, 15%);
        }
    }

    &.button-tertiary {
        text-transform: none;
        padding: 0.2rem 1rem 0.3rem;
        min-width: 0;
        background-color: $brandWhite;
        color: $brandBlue;
        border: 1px solid $brandBlue;
        height: auto;
        line-height: 1.3;
        font-weight: 600;

        &:enabled:not(:focus):hover, &:hover {
            background-color: $brandWhite;
            border-color: darken($brandBlue, 15%);
            color: darken($brandBlue, 15%);
        }
    }

    &.button-disabled {
        background-color: $brandWhite;
        color: $brandGray01;
        border: 1px solid $brandGray01;
            &:enabled:not(:focus):hover, &:hover {
                border-color: $brandGray01;
                color: $brandGray01;
            }
    }

    &.button-block {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    & + & {
        margin: auto 0.75rem
    }
}

body .button.button-submit {
    @extend .button-primary;
}

body .button.button-reset {
    @extend .button-secondary;
}

body .button.button-link {
    @extend .button-secondary;
    border: 0;
}

// Button containers
.buttons-container {
    button {
        margin: auto 0.75rem;
    }
}

// Retrofit existing buttons
body .change-indicator .action-items-frame > .ui-button,
body .ui-dialog .ui-dialog-footer .ui-button {
    @extend .button;
}

// Custom button for creating a ticket
#new-ticket-button {
    z-index: 100;
    position: fixed;
    cursor: pointer;
    right: 1.5rem;
    bottom: 3.5rem;
    color: $brandWhite;
    border-radius: 5rem;
    background-color: $brandBlue;
    box-shadow: inset 0 -1px 0 rgba($brandWhite, 0.4), 0 2px 5px 1px rgba($brandBlack, 0.4);
    transition: background-color 0.2s ease-in-out;
    display: flex;
    align-items: center;

    &:hover {
        background-color: darken($brandBlue, 10%);
    }

    i, span {
        color: $brandWhite;
    }

    i {
        font-size: 0.7rem;
        background-color: $brandBlack;
        border-radius: 50%;
        padding: 0.7rem;
        margin: 0.2rem;
    }

    span {
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0 0.7rem 0 0.3rem;
    }
}
