// Error message container
.error-message {
    width: 100%;

    i {
        font-size: 2em;
        margin-bottom: 0.5em;
    }
}

// General system messages
.system-messages {
    background: $brandAlert;
    text-align: center;
    color: $brandWhite;

    li {
        padding: 1rem;
    }
}

// Update error
.update-error {
    i {
        margin-right: 0.25rem;
    }
}

// Form interaction messages
.form-notification-invalid, .form-notification-pending, .form-notification-success {
    margin: 0 0 1rem 0;
    padding: 1rem;
    text-align: center;
    font-weight: 600;
    overflow-wrap: break-word;

    span {
        color: $gray;
    }

    span, i {
        vertical-align: middle;
        margin: 0 0.25rem;
    }
}

.form-notification-pending {
    background-color: $lightSkyBlueColor;

    .spinner-inline {
        margin-left: 0.25rem;
    }
}

.form-notification-invalid {
    background-color: $errorFill;

    i {
        color: $errorText;
    }
}

.form-notification-success {
    background-color: $brandBlueVariant;

    i {
        color: $brandComplete;
    }
}

// Shared update banner treatment
.update-banner-container {
    display: flex;
    justify-content: center;
    background-color: $lightSkyBlueColor;

    .update-banner {
        width: 75%;

        .update-in-progress, .update-error {
            display: block;
            padding: 1rem;
            text-align: center;
        }

        .update-in-progress {
            display: block;
            padding-top: 1rem;
            padding-bottom: 2rem;
            font-style: italic;
            text-align: center;

            .spinner-inline {
                position: relative;
                top: 0.3rem;
                left: 0.5rem;
            }
        }
    }

    .update-notification {
        display: flex;
        padding: 1rem;

        h4 {
            flex: 2;
            padding-right: 5rem;
        }

        .clear-update-notification {
            flex: 0 0 2rem;
            color: $mediumGray;
        }
    }
}
