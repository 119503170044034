%abstract-pending-vehicles {
    margin: 0 1rem 0 -1rem;

    i {
        vertical-align: middle;
        margin: 1.5rem .25rem;
    }
}

#edit-infleet-modal .blocker {
    position: absolute;
    width: 100%;
    background: rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    top: 0;
}

.infleeting-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $brandBlue;
}

.infleeting-form-view {
    margin: 0 0 0 0;
}

.infleet-btn-col {
  display: grid;
  grid-template-columns: 1fr;
  width: 18rem;
  padding-right: 1rem;
}
.infleet-btn-col-with-status {
  grid-template-columns:1fr 1fr;
}

.infleet-btn-status-container {
  display: inline-block;
  position: relative;
  .infleet-btn-status {
    margin: auto;
    top: 50%;
    width: 100%;
    position: absolute;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    .infleet-btn-status-icon {
      font-size: 0.8rem;
      margin-right: 0.5rem;
      color: orange;
    }
    .infleet-btn-status-text {
      font-size: 0.8rem;
      color: $brandGray01;
    }
    .infleet-btn-status-value {
      font-size: 0.9rem;
      margin: 0.5rem auto 0.3rem;
    }
  }
}

.infleeting-confirmation-container i {
    padding: 2rem;
    font-size: 3rem;
    border-radius: 50%;
    border: 2px solid $brandBlue;
    box-sizing: content-box;
}

.pending-vehicles-invalid {
    @extend %abstract-pending-vehicles;
    background-color: $errorFill;

    i {
        color: $errorText;
    }
}

.pending-vehicles-failed{
    @extend %abstract-pending-vehicles;
    background-color: $veryPaleOrange;

    i {
        color: $lightBrilliantOrange;
    }
}

.pending-vehicles-awaiting-completion{
    @extend %abstract-pending-vehicles;
    background-color: $veryPaleOrange;

    i {
        color: $lightBrilliantOrange;
    }
}

.pending-vehicles-ready{
    @extend %abstract-pending-vehicles;
    background-color: $brandBlue;

    i {
        color: $brandWhite;
    }
}

.buttonColumn{
    min-width: 13em;
    display: inline-grid;
}

.diagnostic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infleet-action-buttons{
    display: inline-block;
    padding-left: 1em;
}

#map, #mapSep {
    width: 100%;
    height: 78%;
    min-height: 680px;
    margin: 0;
}

#create-zone,
#promote-zone, #create-imported-zone {
    margin-left: 1em;
    margin-bottom: 3em;
}

#service-name, .imported-service-name {
    margin: 1em 2em 1em 3em;
}

#home-zone-form,
#home-zone-options-form{
    margin-left: 3em;
}

.home-zone-bottom-btn-container {
    margin: 1em 0 4em 0;
}

.diagnostic-tests {
    .diagnostic-entry {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.5em 0;

        p {
            margin: 0;

            & + p {
                font-size: 0.75rem;
                color: $brandGray01;
            }
        }
    }

    .diagnostic-status {
        &.diagnostic-passed {
            .badge {
                color: $brandComplete;
                background-color: $brandCompleteVariant;
            }
        }

        &.diagnostic-failed {
            .button {
                color: $brandError;
                background-color: $brandErrorVariant;
            }
        }

        &.diagnostic-pending {
            .badge {
                color: $brandGray01;
            }
        }

        &.diagnostic-running {
            i {
                animation: spin 1s infinite linear forwards;
                transform-origin: 52% 52%;
            }

            .badge {
                color: $lightBrilliantOrange;
                background-color: $veryPaleOrange;
            }
        }
    }
}
.infleet-result-container {
  .loading-car-details {
    background: repeating-linear-gradient(45deg, rgb(230, 230, 230) 0, rgb(230, 230, 230) 20px, rgb(204, 204, 204) 20px, rgb(204, 204, 204) 40px);
    background-size: 56px 56px;
    -webkit-animation: slide 20s infinite linear forwards;
    -moz-animation: slide 20s infinite linear forwards;
    animation: slide 20s infinite linear forwards;
    .value {
      span {
        color: $brandGray01;
      }
    }
  }
}
