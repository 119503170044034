// Base setup
body {
    font-family: $fontSans;
    font-size: 1em;
    font-weight: 400;
    line-height: $lineHeight;
    color: $textColor;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        color: $brandBlue;

        &.link {
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .link {
        & > * {
            transition: color 0.2s;
        }

        &:hover, &:hover i {
            cursor: pointer;
            color: $brandBlue;
        }
      
        &.active, &.active i {
            color: $brandBlue;
        }
    }

    .tooltip-link {
        color: $brandBlue;
        
        &:hover, &:hover i {
            cursor: pointer;
        }
    }
}

p {
    line-height: 1.3;
}
