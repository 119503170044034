// Search & result display
#customer-search-form, #ticket-search-form {
    margin: 0 auto;

    .input-field {
        width: 100%;
        margin: auto 0;
    }
}

#customer-search-form {
    max-width: 70em;
}

#ticket-search-form {
    max-width: 90em;

    .input-checkbox {
        justify-content: flex-end;
        width: auto;
        margin: 0.25rem 0.5rem;
    }
}

.related-results-container,
.search-results-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0.5rem;
    min-height: 9rem;
    justify-content: center;
    background-color: $lightestGray;

    .search-results > a {
        text-decoration: none;
    }

    & > .spinner-container {
        @extend .custom-spinner;
    }
}

.result-container {
    margin: 0.7rem 0;
    padding: 0;
    display: block;
    border-radius: 0.1rem;
    color: $brandBlack;

    .info-element {
        padding: 0.6rem 0.3rem;
    }

    .separator {
        border-left: 1px solid $lightGray5;
        flex: 0 0 0.5rem;
        padding-right: 0.5rem;
        margin: 0.5rem 0 0.5rem 0.5rem;
    }

    .details-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        h4 {
            margin: 0;
        }

        .details-row {
            display: flex;
            padding-left: 1rem;
            min-width: 30rem;
            flex: 1;
            justify-content: space-between;

            @include split-responsive {
                & + .details-row {
                    border-top: 1px solid $lightGray5;
                }
            }
        }
    }

    .item-status {
        display: flex;
        flex: 0 0 1.5rem;
        align-items: center;
        justify-content: center;

        &.branded-item {
            background-color: $brandBlue;
        }

        &.pending-item {
            color: $brandGray03;
            background-color: $brandGray01
        }

        &.upcoming-item {
            color: #FFB336;
            background-color: #FFE8C2;
        }

        &.successful-item {
            color: #7ED321;
            background-color: #DAF3BF;
        }

        &.unsuccessful-item {
            color: #E45656;
            background-color: #FDEAED;
        }
    }

    &.hover-state:hover {
        cursor: pointer;
        transition: box-shadow 0.4s;
        box-shadow: 0 0.5rem 0.75rem rgba($brandBlack, 0.1);
    }

    &.customer-result-container {
        margin-left: 0;

        &.with-flags {
            margin-left: -1.2rem;
        }
    }

    &.free-minutes-result-container {
        margin: 0.5rem auto;
        max-width: 90rem;
        width: 100%;
    }

    &.customer-statements-result-container {
        margin: 0.5rem auto;
        max-width: 90rem;
        width: 100%;
    }

    & > .customer-details,
    & > .ticket-details,
    & > .billing-details,
    & > .billing-extended-details,
    & > .free-minutes {
        flex: 1;
        display: flex;
        flex-direction: row;
        background-color: $brandWhite;
        border-radius: 0.1rem;
    }

    & > .ticket-details {
        display: flex;
        flex-direction: column;
        border: 1px solid $lightestGray;


        .info-element {
            padding: 0 0.3rem 0.3rem;
        }

        &.closed-ticket {
            background-color: $lightGray3;
        }

        & > div {
            display: flex;
            flex-flow: row nowrap;
            padding: 0.2rem 0.2rem 0;
            justify-content: space-between;

            &:first-child {
                border-bottom: 2px solid $lightGray3;
            }
        }
    }

    & > .customer-details {
        display: flex;
        flex-direction: row;
        border: 1px solid $lightestGray;

        &.customer-account-inactive {
            background-color: $lightGray;
        }

        & > .customer-flags {
            flex: 0 0 1.2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: $lightOrange;
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;

            i {
                display: block;
                width: 1rem;
                height: 1rem;

                &.flag-placeholder {
                    background: transparent;
                }

                &.driver-licence {
                    @include svg-image('~assets/icons/driver-licence.svg', 80%);
                }

                &.outstanding-balance {
                    @include svg-image('~assets/icons/dollar.svg', 40%);
                }

                &.suspended-account {
                    @include svg-image('~assets/icons/suspended-account.svg', 65%);
                }
            }

            &.hidden {
                visibility: hidden;
                background: none;
            }
        }
    }

    & > .promotion-details {
        background-color: $brandWhite;

        .link {
            font-weight: 700;
            text-transform: uppercase;
        }

        .primary-row {
            color: $gray;
            padding: 0 0.7rem;
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid $mediumGray;

            & > * {
                @include truncate;
                padding: 1rem 0;
            }
        }
    }

    & > .vehicle-details {
        border: 1px solid $lightestGray;
    }

    & > .car-details {
        background-color: $brandWhite;
    }

    & > .billing-details {
        min-height: 4rem;

        &.expanded {
            background-color: $brandGray04;

            .separator {
                border-color: $brandGray03;
            }

            @include split-responsive {
                .details-row + .details-row {
                    border-top: 1px solid $brandGray03;
                }
            }
        }

        .show-more-details {
            display: flex;
            flex: 0 0 2rem;
            color: $brandBlue;
            align-items: center;
            justify-content: center;
            border-left: 2px solid $lightGray;
        }
    }

    & > .billing-extended-details {
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        min-height: 10rem;
        background-color: $brandWhite;
        cursor: default;

        .info-element {
            padding: 0.3rem 0;
        }

        table.summary-table
        {
          td {
            padding: 0.2rem 0.5rem;

            .reverse-btn-disabled {
              opacity: 0.5;
            }
          }

          th {
            border-bottom: 0.9px solid $brandGray02;
          }
        }
	  }

    & > .free-minutes {
        .cancel-free-minutes-link {
            flex: 0.4;
            margin: 1rem;
        }
    }
}

// Related results container (split view)
.related-results-container {
    padding: 0 0.3rem;
    margin-bottom: 1.3rem;
    background: none;

    span {
        text-decoration: none;
    }

    .info-element {
        padding: 0.3rem;
    }

    .result-container {
        margin-bottom: 0;

        &.customer-result-container.with-flags {
            margin-left: 0;
        }
    }
}

// Default result information
.info-element {
    position: relative;
    padding: 0.3rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    mark, a, span {
        font-size: 0.9rem;
    }

    span.title,
    span.value,
    span.highlighted-value {
        @include truncate;
        text-decoration: none;
        display: inherit;
    }

    span.element {
        position: relative;
        min-height: 1.5rem;
        overflow: visible !important;

        .ui-dropdown {
            width: 100% !important;
            border: 0;
            position: absolute;

            .ui-dropdown-label,
            .ui-dropdown-item {
                height: 1.8rem;
                padding: 0;
                font-size: 0.9rem;
            }

            .ui-dropdown-item {
                padding: 0.3rem 0.5rem;
            }
        }
    }

    span.title {
        margin: 0;
        color: $deepGray;
        font-size: 0.8rem;
        font-weight: normal;
    }

    span.value, span.highlighted-value {
        color: $brandBlack;
        font-weight: 500;
        text-decoration: none;
    }

    span.highlighted-value mark {
        background-color: $brandBlue;
        text-decoration: none;
        color: $brandWhite;
    }

    & > * {
        padding: 0.2rem 0;
        line-height: 1.3;
    }

    &.highlighted {
        background-color: $brandWhite;
        border-radius: 0.2rem;
    }

    &.overflow-hidden {
        overflow: hidden;
    }
}
