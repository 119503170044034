// Datepicker trigger
body .ui-calendar {
    width: 100%;

    .ui-datepicker-trigger.ui-button-icon-only {
        height: 1.2em;
        width: 1.2em;
    }
}

// Datepicker theming
body .ui-datepicker {
    margin-top: 0.5em;
    
    thead tr {
        background: none;
        color: inherit;
    }

    .ui-datepicker-title {
        font-weight: 600;
    }

    .ui-datepicker-header {
        .ui-datepicker-prev,
        .ui-datepicker-next {
            color: $brandGray02;
            background: $brandWhite;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                color: $brandWhite;
                background: $brandBlue;
            }
        }
    }

    tbody td.ui-datepicker-today a {
        color: $brandWhite;
        background: $brandGray02;
    }

    .ui-datepicker-buttonbar, .ui-datepicker-header {
        border: 0;
        background: $brandGray05;
        color: inherit;
    }

    .ui-button {
        @extend .button;
        padding: 0 1rem;
        min-width: 0;
        margin: 0.5rem;

        &:enabled:not(:focus):hover, &:hover {
            background: $brandBlue;
            color: $brandWhite;
        }
    }
}

body .ui-datepicker.ui-shadow {
    box-shadow: 0 5px 15px rgba($brandBlack, 0.1);
}

.rc-time-picker-panel {
    width: 14rem !important;
    
    .rc-time-picker-panel-inner {
        width: 100%;
    }

    .rc-time-picker-panel-select > ul > li {
        padding: 0 10%;
        width: 80%;
        font-size: 1rem;
    }
}

body .ui-dialog {
    .ui-datepicker-prev,
    .ui-datepicker-next {
        svg {
            display: none;
        }
    }
}