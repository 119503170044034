#billing-tab-view {
    border: none;

    .ui-tabview-nav {
        margin: 1rem 5rem 0;

        .ui-state-default {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.inner-view-container {
    .summary-container {
        padding: 1rem 5rem;
        display: flex;
        flex-wrap: wrap;
        background-color: $brandWhite;
        min-height: 9rem;
        justify-content: center;

        .summary {
            display: flex;
            flex-grow: 1;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-bottom: 1rem;

            .summary-block {
                flex: 1;
                padding: 0.5rem;

                p {
                    margin: 0.5em;
                    white-space: nowrap;
                }

                .title {
                    display: block;
                    white-space: nowrap;
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }

                .content {
                    border-left: none;
                    border-right: none;
                    min-width: 7rem;
                    border: 1px solid $brandGray04;
                    height: 100%;
                }

                &.highlighted {
                    flex: 2;
                    margin-left: 1rem;
                    margin-right: 1rem;

                    .content {
                        font-weight: bold;
                        height: 100%;
                        background-color: $brandGray04;
                    }
                }
            }
        }

        @include split-responsive {
            padding: 1rem;
        }
    }

    .billing-search, .promotions-search {
        background-color: $lightestGray;

        .billing-search-form .ui-form {
            flex: 0 0 9rem;
            display: flex;
            justify-content: space-between;
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 1rem;
            border-bottom: 2px solid $lightGray4;
        }

        .billing-search-results {
            #expand-all, #collapse-all {
                padding: 0.5rem;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 700;
                color: $gray;

                &:hover {
                    cursor: pointer;
                    color: $brandBlue;
                }
            }
        }
    }

    .billing-search {
        padding: 1rem 4rem;

        @include split-responsive {
            padding: 0;
        }
    }

    .billing-duration {
        text-align: left;
    }

    .billing-modification {
      text-align: right;
    }
}

.billing-extended-details {
    .disabled-btn {
        opacity: 0.6;
        cursor: not-allowed;

        .icon {
            cursor: not-allowed;
        }
    }
}

.billing-tooltip {
    float: right;
    padding: 0.5rem;
    font-size: 0.9em;
    min-width: 20rem;

    li.charge, li.discount, li.tax, p.total {
        display: flex;

        span {
            &.title {
                flex: 3;
                text-align: left;
            }

            &.value {
                flex: 2;
                text-align: right;
            }
        }
    }

    table {
        font-size: 0.9em;
        min-width: 20em;
    }

    td {
        padding-left: 0;
        padding-right: 0;
    }

    ul.promotions {
        margin: 0 0 1rem 1rem;

        li {
            border: none;
        }
    }

    p.total {
        background-color: $lightGray3;
        padding: 0.5rem;

        span {
            font-weight: bold;
        }
    }
}

.billing-tooltip-group-header {
  text-align: center;
  margin-bottom: 20px;
}

#trip-modification-modal {
    div.ui-form {
        min-height: 6rem;
    }

    p {
        font-size: 1rem;
        margin: 0;
    }
}

#fuel-reimbursment-modal {
    min-height: 18rem;

    #amount-input > input {
        text-align: center;
    }
}

#post-fee-modal {
    min-height: 18rem;

    #amount-input > input {
        text-align: center;
    }
}

#trip-adjustment-modal {
    p {
        font-size: 1rem;
    }

    #trip-adjustment-table {
        margin-bottom: 1rem;
        width: 100%;

        & > thead > tr, & > tbody > tr {
            & > th, & > td {
                padding: 1rem;
                text-align: left;
                font-size: 1rem;
                height: 4.5rem;
            }
        }

        & > tbody > tr:first-child {
            border-top: 1px solid $brandBlack;
            background-color: $brandGray05;
        }
    }
}

// Field widths
.info-free-minutes_locations {
    flex: 2;
}

.fee-reversal-confirmation {
    text-align: center;
    font-style: italic;
}
