form.import-form {
    margin-bottom: 1rem;

    h4 {
        margin-top: 0.5rem;
    }

    i {
        cursor: pointer;
    }

    .input-field .checkbox-option,
    .input-field .radio-option {
        margin-bottom: 0;
    }

    .file-upload-container {
        padding: 0;
    }

    .ui-g {
        align-items: center;
    }
}

#import-filter-form {
    border-top: 1px solid gray;
}

.table-loading-spinner {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 5rem;
}

.filter-submit-container {
    vertical-align: middle;
    
    .filter-submit-box {
        height: 100%;
        display: inline-flex;
    }
}
