// Modal base
body .ui-dialog  {
    display: flex;
    flex-direction: column;
    border-radius: 0.2rem;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    // padding-bottom: 1rem;

    .ui-dialog-titlebar {
        padding: 2rem 3rem;
        letter-spacing: 0.1em;
        padding-bottom: 0;
        font-weight: 700;

        .ui-dialog-title {
            text-transform: uppercase;
            position: relative;
            letter-spacing: 0.1em;
    
            &::after {
                display: block;
                content: '';
                width: 3rem;
                height: 0;
                border-bottom: 3px solid $brandBlue;
                left: 0;
                top: 100%;
            }
        }

        .ui-dialog-titlebar-close {
            border: 2px solid $lightGray;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            line-height: 2;
            position: absolute;
            top: 1rem;
            right: 1rem;
            margin: 0;
            
            path {
                fill: $gray;
            }
        }
    }

    .ui-dialog-content {
        overflow: inherit;
        padding: 1rem 3rem 0.5rem;
        min-height: 10rem;
        
        .spinner-container {
            height: inherit;
        }
    }

    .modal-form-header {
        padding-top: 1.5em;
        font-weight: 600;
    }

    .ui-dialog-footer {
        border: 0;
        padding: 0 0 1rem;
        display: flex;
        width: 100%;
    }

    &.ui-shadow {
        box-shadow: 0 10px 30px rgba($brandBlack, 0.1);
    }
}

// Overlay backdrop
body .ui-widget-overlay {
    background-color: $brandWhite;
}

#trip-adjustment-modal {
    overflow: visible;
}
