.file-upload-container {
    text-align: center;
    padding-bottom: 1rem;

    table {
        th, td {
            font-size: 1rem;
            text-align: left;
        }

        a {
            cursor: pointer;
        }

        a:hover {
            color: $brandBlue;
        }
    }

    .field-title {
        line-height: 1.5rem;

        .drag-and-drop {
            color: $gray;
            font-weight: normal;
        }

        .browse-files {
            color: $gray;
            text-decoration: underline;
            font-weight: bold;
        }

        .spinner-inline {
            margin-left: 1rem;
            vertical-align: text-bottom;
        }
    }

    & > div.ui-file-upload {
        padding: 1rem;
        min-height: 2rem;
        background-color: $lightestGray;
        border: 2px dotted $lightGray3;
        margin-bottom: 1rem;
    }
}

.file-upload-container-disabled {
    & > div.ui-file-upload {
        border: 2px dotted $redDark;
    }
}

#import-upload-form-sumbit {
    margin: 1.5rem;
}
