// General tab treatment
body .ui-tabview.ui-tabview-top > .ui-tabview-nav {
    border-radius: 0;
    border: 0;
    min-height: 3rem;

    & > li {
        background: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.85rem;
        letter-spacing: 0.04em;

        .ui-tabview-left-icon {
            text-align: right;
            margin-right: 0.3rem;
        }

        &.ui-state-active a,
        &.ui-state-active a .ui-tabview-left-icon {
            color: $brandBlue;
        }

        &:not(.ui-state-active) {
            border-color: transparent;
        }

        &:not(.ui-state-active):not(.ui-state-disabled):hover {
            background: none;
        }
    }
}

// Primary level tabs
body .primary-tab-view {
    &.ui-tabview.ui-tabview-top > .ui-tabview-nav {
        background: $brandGray05;

        li {
            margin-left: 2rem;
        }
    }
}

// Second level tabs
body .secondary-tab-view {
    &.ui-tabview.ui-tabview-top > .ui-tabview-nav {
        border-bottom: 2px solid $brandGray04;
        
        li {
            margin-bottom: -0.05rem;
            margin-right: 2rem;

            a {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
