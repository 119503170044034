// Reusable note styles
.note-cell {
    .description {
        font-size: 0.8em;
        word-wrap: break-word;
    }

    .details {
        font-size: 0.6em;
        padding-top: 0.5rem;

        span {
            margin-right: 0.5em;
        }

        .action-items-frame {
            padding-top: 0.5rem;
            display: flex;
            justify-content: flex-end;

            .ui-button,
            .ui-button:hover {
                @extend .unstyled-button;
                background: none !important;
                margin-left: 0.5rem;
            }
        }
    }
}

.add-note-frame {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    textarea {
        @extend .font-reset-sans;
        padding: 0.3rem;
        width: 100%;
        max-width: 100%;
        max-height: 6em;
        height: 6em;
        background-color: $lightGray2;
        border-color: lighten($gray, 60%);
        font-size: 0.9rem;
    }

    .ui-button,
    .ui-button:hover {
        @extend .unstyled-button;
        background: none !important;
        margin: 0.2em 0 0 0.5em;
    }
}
