// Basic text formatting
.text-error,
.text-error span {
    color: $errorText;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-clear {
  float: none;
  clear: both;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-highlighted {
    padding: 0.2rem 0.4rem;
    margin: 0 0.5rem;
    background-color: lighten($brandBlue, 50);
}

// Alignment
.align-items-left {
    display: flex;
    justify-content: flex-start;
}

.align-items-right {
    display: flex;
    justify-content: flex-end;
}

// General list reset
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Prime FA reset
.fa-reset {
    &::before {
        content: '';
    }
}

// Basic hide
.hide {
    display: none;
}

// Car spinner setup
.custom-spinner {
    @include svg-image('~assets/icons/zoom.svg', contain);
    animation: car-zoom 1.5s linear forwards infinite;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    .ui-progress-spinner {
        visibility: hidden;
    }
}

// Unstyled button (e.g., link with an icon)
.unstyled-button {
    font-size: 0.85rem;
    font-weight: 600;
    min-width: 0;
    padding: 0;
    color: inherit;
    background: none;
    text-transform: uppercase;

    span {
        margin: 0;
        line-height: 0.9;
    }
}

// Field helper/tip
.field-helper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    text-transform: uppercase;
    font-weight: 700;
}

.with-field-helper {
    position: relative;
    padding-right: 0 !important;

    .input-field {
        position: relative;
        z-index: 2;
        margin: 0;

        input {
            padding-right: 1.5em;

            &:focus {
                background: $brandWhite;
            }
        }
    }
}

// Common date forms
.to-from-date-forms {
    max-width: 65%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding: rem(40) rem(20);
    flex-wrap: wrap;

    & > .input-field,
    & > .button {
        margin: 0.5rem;
    }
}

// Currency indication helper
.currency-prefix {
    position: relative;
    margin-top: -0.5em;

    &::before {
        content: '$';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translatey(-50%);
    }
}

// Placeholder treatment
.placeholder {
    position: relative;

    & > * {
        filter: grayscale(100%);
        opacity: 0.25;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        z-index: 90;
        background: repeating-linear-gradient(45deg, rgba($brandGray01, 0.2), rgba($brandGray01, 0.2) 1rem, rgba($brandGray01, 0.1) 1rem, rgba($brandGray01, 0.1) 2rem);
    }

    &.loading {
        overflow: hidden;

        &::before {
            width: auto;
            left: -10rem;
            right: -10rem;
            animation: placeholder-loading 1s forwards infinite linear;
        }
    }
}

.hidden {
    display: none;
}

.block:after {
  content: "";
  display: block;
  position: fixed;
  top: auto;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
}

.blocker {
   width: calc(100% - 0.6rem);
   height: 100%;
   left: 0;
   position: fixed;
   z-index: 2;
   background: rgba(0, 0, 0, .2);
 }

.w100 {
    width: 100%;
}


.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
}
