// General table styles
div.summary-table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1.5rem;

    & + .link {
        margin: -0.5rem 0 3rem;
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;
    }
}

table.summary-table {
    width: 100%;
    border-spacing: 0;

    thead {
        tr {
            background-color: $brandWhite;
        }
        
        th {
            font-size: 0.9rem;
            border-bottom: 1px solid $brandGray02;
        }
    }
    
    tbody {
        tr > td {
            font-size: 0.9rem;
            border-bottom: 1px dashed $lightGray;
            line-height: 1.3;
        }
        
        tr:nth-child(odd) {
            background-color: $brandWhite;
        }
        
        tr:nth-child(even) {
            background-color: $lightGray2;
        }
        
        tr:last-child > td {
            border-bottom: none;
        }
        
        a {
            cursor: pointer;
        }
    }
    
    th, td {
        padding: 0.5rem;
        min-height: 3rem;
        max-width: 10rem;
        font-size: 1rem;
        
        & > label, & > span, & > i {
            display: inline-block;
            max-width: 100%;
            // line-height: 110%;
            vertical-align: middle;
        }
    }
    
    th:not([class*="text-"]) {
        text-align: left;
    }
    
    td a {
        color: $brandBlack;
        text-decoration: underline;
    }

    .secondary-content & {
        tbody {
            tr > td {
                font-size: 0.75rem;
            }

            .info-element {
                padding: 0;

                span.value {
                    font-size: 0.75rem;
                }
            }
        }
    }

    &.striped {
        tr {
            border-bottom: 1px dashed $deepGray;
        }

        tr:last-child {
            border-bottom: none;
        }

        tbody {
            tr:nth-child(odd) {
                background: $brandWhite;
            } 
            
            tr:nth-child(even) {
                background-color: $lightGray2;
            }
        }
    }
}
