// SVG background helper
@mixin svg-image($svgFileUrl, $svgBackgroundSize: 100%) {
    background-image: url($svgFileUrl);
    background-size: $svgBackgroundSize;
    background-position: center;
    background-repeat: no-repeat;
}

// Force a hardware render
@mixin gpu-rendering {
    transform: translate3d(0, 0, 0);
}

// Basic clearfix
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Basic truncate to one line
@mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Native scrollport helper (one direction)
@mixin scrollport($direction) {
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: pan-#{$direction};
    -ms-overflow-style: auto;
}

// Font aliasing helper
@mixin anti-aliasing($type: antialiased) {
    -webkit-font-smoothing: $type;
    font-smoothing: $type;
    font-smooth: always;
}

// Aspect ratio sizer
@mixin aspect-ratio($width: 1, $height: 1, $inner: false, $inset: 0) {
    position: relative;

    @if $inner {
        & > * {
            position: absolute;
            top: $inset;
            left: $inset;
            bottom: $inset;
            right: $inset;
        }
    }

    &::before {
        display: block;
        content: '';
        padding-top: percentage($height / $width);
    }
}

// Icon generator
@mixin icon($name, $code, $pseudo: before, $class: true, $font: false) {
    @if $class {
        .icon-#{$name}::#{$pseudo} {
            content: $code;

            @if $font {
                font-family: $fontIcons;
            }
        }
    } @else {
        &::#{$pseudo} {
            content: $code;

            @if $font {
                font-family: $fontIcons;
            }
        }
    }
}

// Split view + responsive media query equivalent
@mixin split-responsive {
    .split-screen-open & {
        @content;
    }

    @include media('<=large') {
        @content;
    }
}
