body {
    .ui-dialog {
        &.ui-shadow {
            @include overlay-content-shadow();
        }
        
        .ui-dialog-titlebar {
            background-color: $contentBgColor;
            color: $textColor;
            padding: $headerPadding;
            
            .ui-dialog-title {
                font-size: 1.25em;
                letter-spacing: .005em;
                margin-top: .25em;
                line-height: 2em;
            }
            
            .ui-dialog-titlebar-icon {
                border: 0 none;
                padding: 0;
                @include border-radius(50%);
                @include transition(background-color$transitionDuration);
                height: 1.5em;
                width: 1.5em;
                text-align: center;
                margin-top: .5em;
                
                &:hover, &:focus {
                    @include hover-element();
                }
                
                .fa {
                    color: $textSecondaryColor;
                    display: inline-block;
                }
                
                .fa-extlink {
                    @include icon_override('fullscreen');
                }
                
                .fa-newwin {
                    @include icon_override('fullscreen_exit');
                }
            }
        }
        
        .ui-dialog-content {
            padding: $contentPadding;
        }
        
        .ui-dialog-footer {
            text-align: right;
            border: 0 none;
            border-top: 1px solid $dividerColor;
            
            .ui-button {
                background-color: #ffffff;
                color: $textColor;
                @include no-shadow();
                width: auto;
                
                .fa {
                    color: $textSecondaryColor;
                }
                
                &:hover {
                    @include hover-element();
                }
            }
        }
        
        .ui-confirm-dialog-severity {
            margin: 0px .75em;
        }
    }
    
    .ui-lightbox {
        &.ui-shadow {
            @include overlay-content-shadow();
        }
        
        .ui-lightbox-caption {
            padding: $headerPadding;
            
            .ui-lightbox-caption-text {
                margin: 0;
            }
            
            .ui-lightbox-close {
                @include border-radius(50%);
                @include transition(background-color$transitionDuration);
                padding: 0;
                margin: 0;
                width: 1.5em;
                height: 1.5em;
                            
                &:hover {
                    background-color: $primaryLightColor;
                    @include border-radius(50%);
                    @include transition(background-color$transitionDuration);
                }
            }
        }
        
        .ui-lightbox-content-wrapper {
            .ui-lightbox-nav-right, .ui-lightbox-nav-left {
                top: 40%;
                
                .fa {
                    @include transition(color$transitionDuration);
                    font-size: 3em;
                    color: $primaryLightColor;
                }
            }
        }
    }
    
    .ui-overlaypanel {
        &.ui-shadow {
            @include overlay-content-shadow();
        }
        
        .ui-overlaypanel-close {
            background-color: $accentColor;
            @include border-radius(50%);
            @include transition(background-color$transitionDuration);
            right: -16px;
            top: -16px;
            width: 2em;
            height: 2em;
            line-height: 2em;
            text-align: center;
            @include content-shadow();
            
            span {
                color: $accentTextColor;
                line-height: inherit;
            }
            
            &:hover {
                background-color: $accentDarkColor;
            }
        }
    }
    
    .ui-tooltip {
        @include opacity(.9);
        
        .ui-tooltip-text {
            background-color: $tooltipBgColor;
        }
        
        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                border-top-color: $tooltipBgColor;
            }
        }
        
        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                border-bottom-color: $tooltipBgColor;
            }
        }
        
        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: $tooltipBgColor;
            }
        }
        
        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                border-right-color: $tooltipBgColor;
            }
        }
    }
}
