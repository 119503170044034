.fleet-management-view-container {
  .fleet-management-filter-car-filter {
    width: 100%;
    overflow: auto;
    background: #F0F0F0;
    margin-bottom: 10px;
  }

  .car-status-card-selected {
    background-color: #fff6Ac;
  }

  .car-status-card {
    cursor: grab;
    box-shadow: 2px 2px 5px black;

    .ui-card-content {
      line-height: 1.1;
    }

    .car-status-card-value {
      font-size: 2.5em;
      float: left;
    }

    .car-status-card-percentage {
      font-size: 1.5em;
      float: right;
      margin-top: 20px;
      color: $brandBlue;
    }

    .car-status-card-status {
      clear: both;
      float: none;
      font-size: 0.75em;
      font-weight: bolder;
      color: $brandGray02;
      padding: 5px 0 10px;
      width: 100%;
      text-align: center;
    }

    .car-status-card-progressbar {
      border-radius: 3px;
      background: $brandGray04;
      margin: 5px 0 0;

      .ui-widget-header {
        background: $brandBlue;
      }
    }

    .car-status-card-close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $brandGray02;
      cursor: pointer;
    }

    .car-status-card-close-btn:hover {
      color: $brandBlack;
    }
  }

  .fleet-management-map-container {
    height: calc(100vh - 12.5rem - 160px);
    position: relative;
    box-shadow: 0 0 3px black;
  }

  .fleet-management-map-control {
    position: absolute;
    top: 90px;
    left: 10px;
    padding: 10px 20px;
    border: 1px solid $brandGray03;
    background: $brandWhite;

    i {
      margin-right: 5px;
    }

    .parking-toggle {
      margin-left: 30px;
    }
  }

  .info-window {
    width: 200px;
    min-width: 200px;
    position: absolute;
    bottom: 30px;
    left: -102px;
    padding: 10px 2px 0;
    background: $brandWhite;
    color: $brandBlack;
    border-radius: 5px;
    border: 2px solid $brandBlue;
    text-align: center;
    z-index: 1000;
    font-size: 1.3em;

    .info {
      margin-bottom: 10px;

      .info-value {
        color: $brandBlack;
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }

  .fleet-modal-cards {
    margin: 20px 0 0 20px;
    height: 400px;

    .fa-caret-down:before {
      content: "\f0d7" !important;
    }
  }
}

.map-meter-info-window {
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  position: relative;
  text-align: center;
  z-index: 5000;
  max-width: 648px;
  max-height: 222px;
  min-width: 200px;
  background-color: white;
  position: relative;
  padding: 10px 5px 2px 5px;
  margin: 5px 5px 5px 5px;
  font-size: 13px;
  display: inline-block;

  .map-meter-info-window-rate {
    float: left;
    padding: 0 3px 0 3px;
  }

  .map-meter-info-window-time-limit {
    float: right;
    padding: 0 3px 0 3px;
  }

  .map-meter-info-window-head {
    clear: both;
    float: none;
    font-size: 1em;
    font-weight: 400;
    padding: 10px 3px 3px 0;
    width: 100%;
    text-align: center;
  }

  .map-lot-info-window-head {
    clear: both;
    float: none;
    font-size: 1em;
    font-weight: 400;
    padding: 10px 5px 10px 0;
    width: 100%;
    text-align: center;
  }

  .map-meter-info-window-in-effect {
    clear: both;
    float: none;
    font-size: 0.9em;
    font-weight: 700;
    color: #9B9B9B;
    padding: 5px 0 0;
    width: 100%;
    text-align: center;
  }

  .car-plate {
    color: $brandBlue;
    font-size: 1.2em;
    font-weight: 700;
  }

  .car-state {
    color: $brandBlack;
    font-size: 1em;
  }

  .info {
    margin-bottom: 10px;
  }
}

.marker-container {
  cursor: pointer;

  .marker-img {
    position: absolute;
    bottom: 0;
  } 

  .fleet-management-marker-label {
    width: 46px;
    min-width: 46px;
    border: 1px solid $brandGray02;
    padding: 1px 3px;
    text-align: center;
    color: $brandBlack;
    background: $brandWhite;
    position: absolute;
    margin: 5px 0 0 -10px;
  }

  .label-warning-low {
    background: rgb(241, 181, 70);
  }

  .label-warning-extrem {
    background: rgb(255, 83, 64);
  }
}

.fleet-management-parkigMeterMarker {
  border-radius: 50%;
  display: inline-block;
  z-index: 1000;
  position: relative;
  cursor: pointer;
}

.parking-meter-marker-14 {
  height: 3px;
  width: 3px;
}

.parking-meter-marker-15 {
  height: 5px;
  width: 5px;
}

.parking-meter-marker-16 {
  height: 7px;
  width: 7px;
}

.parking-meter-marker-17 {
  height: 9px;
  width: 9px;
}

.parking-meter-marker-18 {
  height: 11px;
  width: 11px;
}

.fleet-management-parkingLotMarker {
  z-index: 1100;
  cursor: pointer;
  position: relative;
}

.lot-info {
  display: none;
  width: 100%;
  z-index: 9000;
  margin: 10px 0 0 0;
  position: relative;
}

.lot-hover-info {
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  text-align: center;
  background-color: bisque;
  width: 100%;
  white-space: nowrap;
  font-size: 1.2em;
  z-index: 9000;
  padding: 5px 5px 5px 5px;
}

.fleet-management-parkingLotMarker:hover~.lot-info {
  display: block;
}

.meter-rate-less3 {
  background-color: #00BCE2;
  color: #00BCE2;
}

.meter-rate-bet3-5 {
  background-color: #f1b546;
  color: #f1b546;
}

.meter-rate-more5 {
  background-color: #ff5340;
  color: #ff5340
}

.marker-close {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}