// Custom iconography
@font-face {
    font-family: $fontIcons;
    src: url('~assets/fonts/custom-icons.eot?z9te3u');
    src: url('~assets/fonts/custom-icons.eot?z9te3u#iefix') format('embedded-opentype'),
    url('~assets/fonts/custom-icons.ttf?z9te3u') format('truetype'),
    url('~assets/fonts/custom-icons.woff?z9te3u') format('woff'),
    url('~assets/fonts/custom-icons.svg?z9te3u#custom-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon, [class^="icon-"], [class*=" icon-"] {
    font-family: $fontIcons !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Icon map (@todo: adjust linter so it doesn't balk at having these on separate lines with proper indentation)
$icons: (activity-log: '\e900', add-list: '\e901', arrow: '\e902', car: '\e903', tool: '\e904', check: '\e907', chevron: '\e908', close: '\e909', details: '\e90a', dollar: '\e90b', driver-licence: '\e90c', info: '\e90d', management: '\e90e', pencil: '\e90f', profile: '\e910', split-screen: '\e911', suspended-account: '\e913', ticket: '\e914', tickets: '\e915', trip: '\e916', warning: '\e917');

// Make d'em icons
@each $name, $code in $icons {
    @include icon($name, $code);
}

// Font awesome icons rotation
.fa.fa-rotate-45 {
    transform: rotate(45deg);
}

// Icon within filled circle/badge
span.badge {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: $brandGray04;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    line-height: 1;

    i.fa {
        text-align: center;
        // vertical-align: middle;
    }
}

// Custom iconography
i.icon {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    vertical-align: bottom;

    &.ticket {
        @include svg-image('~assets/icons/ticket.svg', 150%);
    }

    &.trip {
        @include svg-image('~assets/icons/trip.svg', 85%);
    }

    &.details {
        @include svg-image('~assets/icons/details.svg', 85%);
    }

    &.payment {
        @include svg-image('~assets/icons/dollar.svg', 50%);
        background-color: $brandBlack;
    }
}

.summary-container .button-link .fa {
    @include icon(add-list, map-get($icons, add-list), $pseudo: before, $class: false, $font: true);
    font-size: 1.2rem;
    vertical-align: -15%;
}

.secondary-content .fa.fa-car {
    @include icon(car, map-get($icons, car), $pseudo: before, $class: false, $font: true);
    font-size: 0.7rem;
    padding: 0 1.1rem 0 0.3rem;
}

.fa-trip {
  @include icon(trip, map-get($icons, trip), $pseudo: before, $class: false, $font: true);
  font-size: 0.7rem;
  padding: 0 1.1rem 0 0.3rem;
}

.secondary-content .fa.fa-user {
    @include icon(profile, map-get($icons, profile), $pseudo: before, $class: false, $font: true);
    font-size: 1.6rem;
    width: 1.3rem;
    vertical-align: bottom;
    height: 0.8em;
}
