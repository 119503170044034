body {
    .ui-tabview {
        display: flex;
        flex-direction: column;

        &.ui-widget-content, .ui-widget-content {
            border: 0;
            border-radius: 0;
        }
    }

    .ui-inputtext {
        &:disabled {
            border-style: solid;
        }
    }

    .ui-dropdown-filter-container {
        color: $brandBlack !important;
        background-color: $brandWhite !important;
        
        span {
            display: none; // TODO fix the issue with the font awesome icon
        }

        &:hover {
            input {
                color: $brandBlack !important;
                border-bottom: 2px solid $gray !important;
            }
        }
    }

    .ui-dropdown-label {
        min-height: 1.5rem;
    }
    
    .ui-button {
        background-color: $brandBlue;
        box-shadow: none;

        &:enabled:not(:focus):hover {
            background-color: darken($brandBlue, 10%);
        }
    }
    
    .ui-radiobutton {
        .ui-radiobutton-box {
            width: rem(20);
            height: rem(20);
            border-radius: 100%;
            box-shadow: inset 0 0 0 0.2rem $brandWhite;

            .ui-radiobutton-icon {
                left: 0;
                top: 0;
                width: rem(20);
                height: rem(20);
                background: $brandBlue;
                border-radius: 100%;

                &::before {
                    content: none;
                }
            }
        }

        .ui-radiobutton-box,
        .ui-radiobutton-box.ui-state-active {
            border-color: $brandGray03;
        }
    }

    .ui-dialog {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
      
        .ui-dialog-titlebar {
            font-weight: bold;
            text-transform: uppercase;
        }

        .ui-dialog-content {
            flex: 1;
            // overflow: auto !important;
        }
    }

    .ui-calendar {
        position: relative;
        // display: initial;

        .ui-datepicker-trigger.ui-button-icon-only .ui-button-icon {
            color: $brandBlue;
        }
        .ui-datepicker-trigger.ui-calendar-button.ui-button-icon-only {
          height: 1.8em;
        }
    }

    .ui-autocomplete-input-token {
        padding: 0.1rem 0.1rem 0.1rem 0;
    }

    .ui-clickable.fa {
        font-family: "Material Icons";
    }

    .ui-tooltip.light {
        min-width: 20rem;

        .ui-tooltip-text {
            background-color: $lightestGray !important;
            border-color: $lightGray !important;
            color: $gray !important;
        }

        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                border-top-color: $lightGray;
            }
        }
        
        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                border-bottom-color: $lightGray;
            }
        }
        
        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: $lightGray;
            }
        }
        
        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                border-right-color: $lightGray;
            }
        }
    }

    .ui-tooltip.dark {
        min-width: 20rem;

        .ui-tooltip-text {
            background-color: $tooltipGray !important;
            border-color: $tooltipGray !important;
            color: $brandWhite !important;
        }

        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                border-top-color: $tooltipGray;
            }
        }
        
        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                border-bottom-color: $tooltipGray;
            }
        }
        
        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: $tooltipGray;
            }
        }
        
        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                border-right-color: $tooltipGray;
            }
        }
    }
}
