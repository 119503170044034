#ticket-sorting-dropdown {
    min-width: 20rem;
}

#ticket-search-categories-modal-toggle,
#ticket-form-categories-modal-toggle {
    position: absolute;
    font-size: 1.15rem;
    text-transform: uppercase;
    top: 1.5rem;
    right: 1rem;

    span {
        padding-left: 0.5rem;
        font-weight: 700;
        vertical-align: 10%;
    }
}

#ticket-search-categories-modal-toggle {
    right: 0.5rem;
}

#ticket-form  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .note {
        margin: 0 1rem;
        padding: 1rem 0;
        position: relative;
        line-height: 1.3;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $lightGray5;

        .delete-note-link {
            font-weight: bold;
            text-transform: uppercase;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;

            i {
                font-size: 0.9rem;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .edit-details {
        padding-right: 1rem;
        margin-bottom: 1rem;
        margin-top: 0;
        padding-top: 0;
        color: $brandGray01;
        font-size: 0.8rem;
        line-height: 1.3;
        font-style: italic;
        width: 80%;
        float: right;

        small {
            margin: 0 0.2rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .ticket-notes {
        border: 1px solid $lightGray5;
        padding-bottom: 3rem;
        height: 20rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .note-content {
        display: inline-block;
        width: 100%;
        margin: 1rem 0;
        padding: 1rem 0;
    }

    .new-note {
        display: flex;
        flex-direction: column;
    }

    .ticket-body {
        flex: 3;
        min-width: 35rem;

        & > div {
            width: 100%;
        }
    }

    .ticket-settings {
        flex: 1;
        min-width: 30%;
        height: 100%;
        position: sticky;
        z-index: 3;
        top: 1.5rem;

        .block:after {
          position: absolute;
          width: 100%;
        }

        .blocker {
          position: absolute;
          width: 100%;
        }

        .inner {
            display: block;
            padding: 1rem;
            background-color: $lightSkyBlueColor;
        }

        #submit-ticket-button-resubmit {
            z-index: 4;
        }
    }

    & > div {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

#note-field-header {
    display: flex;
}

#note-field-label {
    flex: 1;
    padding-top: 0.4rem;
}

#add-note-link {
    flex: 1;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.5rem 0;

    i {
        font-size: 0.9rem;
    }
}

#note-field-error {
    width: 100%;
    padding: 0.5rem 0;
}

#edit-ticket-view {
    display: flex;
    justify-content: center;
}

#ticket-categories-modal {
    display: flex;

    .ui-dialog-content {
        overflow: auto;
    }

    #ticket-groups-tabs {
        border: none;

        ul.ui-tabview-nav {
            border-bottom: 1px solid $lightGray3;
            min-height: auto;
            height: rem(50);

            li {
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 0.5rem;

                &.ui-state-active {
                    border-bottom: 2px solid $brandBlue;

                    a {
                        color: $brandBlue;
                    }
                }
            }
        }

        div {
            height: 62vh;
        }
    }

    li.group {
        font-weight: 700;
        text-transform: uppercase;
        padding-right: 1rem;
        margin: 0.75rem 0;
        line-height: 1.2;
    }

    li.category, li.subcategory {
        margin: 0.75rem 0;
        font-size: 1rem;
    }

    li.subcategory span {
        padding: 0.1rem 0.6rem 0.2rem;
        border-radius: 1rem;
        transition: background-color 0.2s;

        &:hover {
            cursor: pointer;
            background-color: $brandGray03;
        }

        &.selected {
            background-color: $brandBlue;
            color: $brandWhite;
            font-weight: 500;
        }
    }

    .ticket-categories {
        min-height: 20rem;
        display: flex;
        padding-bottom: 0;
        margin-bottom: 1rem;
        border-bottom: 1px solid $lightGray3;

        div.ui-tabview {
            height: 100%;
        }

        h4 {
            margin: 0;
        }

        div {
            padding: 0;
            margin: 0;

            &.categories-filter {
                padding: 0.65rem 1rem 0rem;
                height: rem(49);

                input {
                    padding: 0.25rem 0;
                }
            }

            &.selected-ticket-categories {
                border-top: 1px solid $lightGray3;
                padding: 1rem;
                background-color: $lightestGray;
            }

            &.categories-listing {
                height: 100%;

                & > ul {
                    height: 100%;
                    overflow: auto;

                    &:last-child {
                        padding-left: 1rem;
                        border-left: 1px solid $lightGray3;
                    }
                }
            }
        }

        .ticket-category-value {
            @extend .link;
        }

        .selected-category {
            font-weight: 600;
            padding: 0.3rem 0.1rem;

            span {
                margin: 0 0.5rem;
                display: inline-block;
            }

            .fa-car {
                font-size: 0.75rem;
            }

            .fa-user {
                text-indent: 0.15rem;
            }

            i {
                width: 1rem;
            }

            i.link:hover {
                color: $brandBlue;
            }
        }
    }
}

.ticket-confirmation-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    i {
        padding: 2rem;
        font-size: 3rem;
        border-radius: 50%;
        border: 2px solid $brandBlue;
    }

    h2, i {
        color: $brandBlue;
    }

    .ticket-confirmation {
        display: flex;
        justify-content: space-between;
        border: 1px solid $lightestGray;
    }
}

.view-ticket-link, .clear-update-notification {
    display: flex;
    align-items: center;
}

.view-ticket-link {
    padding-left: 2rem;
    padding-right: 2rem;
    border-left: 1px solid $mediumGray;

    a.link {
        color: $gray;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: $brandBlue;
        }
    }
}

.categories-input-field {
    position: relative;
}

.categories-input-field span.ui-autocomplete, .categories-input-field ul.ui-autocomplete-multiple-container {
    width: 100%;
}

.form-control-calendar .ui-inputtext {
    margin-top: 11px;
}
