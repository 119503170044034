.wakeup-provider-container{
    border: 1px solid $brandGray02;
    box-shadow: 0 0 3px 1px $brandGray04;
    margin: 1em;
    padding: 0.5em;
    .title {
      color: $brandGray02;
      display: inline-block;
      .current-status {
        margin-left: 10px;
      }
      .COMPLETED {
        color: $brandComplete;
      }
      .STARTED, .STARTING, .STOPPED, .STOPPING {
        color: $actionColor;
      }
      .ABANDONED, .UNKNOWN {
        color: $brandAlert;
      }
      .FAILED {
        color: $brandError;
      }
      a {
        margin-left: 1em;
        cursor: pointer;
        color: $brandGray01;
      }
      a:hover {
        color: $brandBlue;
      }
      .fa-fresh {
        transition: transform .8s ease-in-out;
        -webkit-transition: -webkit-transform .8s ease-in-out;
        -ms-transition: -ms-transform .8s ease-in-out;
      }
      .fa-fresh:hover {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
      }
    }

    .fleet-name-container {
      .fleet-name {
        font-size: 2.5em;
        margin: 0.5em 0;
      }
    }

    .status-time {
      float: right;
      color: $brandGray01;
    }

    @keyframes animate-stripes {
      0% {
        background-position: 0 0;
      }
  
      100% {
        background-position: 60px 0;
      }
    }
  
    .status-bar {
      width: 100%;
      margin: 0.5em 2em 0.5em 0;
      border: 2px solid $brandGray02;
      position: relative;
      display: flex;
      .jasper {
        background: rgb(85, 195, 223);
        height: 4em;
        border-radius: 0 2px 2px 0;
        transition: all 1s linear;
        text-align: center;
        padding: 5px 0;
      }
      .jasper-progress {
        background-size: 30px 30px;
        background-image: linear-gradient(
          -135deg,
          rgba(35, 35, 155, 0.5) 25%,
          transparent 25%,
          transparent 50%,
          rgba(35, 35, 155, .5) 50%,
          rgba(35, 35, 155, .5) 75%,
          transparent 75%,
          transparent
        );
        animation: animate-stripes 1.5s linear infinite;
        animation-direction: reverse;
        border-radius: 0;
      }
      .twilio {
        background: rgb(226, 93, 93);
        height: 4em;
        border-radius: 2px 0 0 2px;
        transition: all 1s linear;
        text-align: center;
        padding: 5px 0;
      }
      .twilio-progress {
        background-size: 30px 30px;
        background-image: linear-gradient(
          135deg,
          rgba(155, 35, 35, 0.5) 25%,
          transparent 25%,
          transparent 50%,
          rgba(155, 35, 35, .5) 50%,
          rgba(155, 35, 35, .5) 75%,
          transparent 75%,
          transparent
        );
        animation: animate-stripes 1.5s linear infinite;
        border-radius: 0;
      }
      .status-text {
        position: absolute;
        width: 100%;
        text-align: center;
        text-shadow: 0 0 3px black;
        color: $brandWhite;
        padding: 1em;
      }
    }

    .status-num {
      width: 100%;
      position: relative;
      .left-num {
        display: inline-block;
        width: 50%;
        text-align: right;
        padding-right: 20px;
        border-right: 2px solid $brandGray02;
      }
      .right-num {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-left: 20px;
      }
    }
    .switch-btn-container {
      text-align: center;
      position: relative;
      display: flex; 
      border-left: 1px solid $brandGray03;
      @media only screen and (max-width: 1024px) {
          border-top: 1px solid $brandGray03;
          border-left: none;
          padding-top: 1em;
      }

      .button {
        min-width: 120px;
        margin: auto;
      }
    }
}
.provider-selector {
  margin-top: 15px;
}
.wake-up-provider-view {
  .switching-failure-container {
    height: 70vh;
    .ui-progress-spinner {
      margin-top: 30vh;
    }
    .no-failure {
      font-size: 1.3em;
      font-weight: 200;
      padding-top: 30vh;
      width: 100%;
      text-align: center;
    }
  }
}