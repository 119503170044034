// Rem calculator (feed it pixels)
@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: unit-strip($pxval);
    }
  
    $base: $em-base;

    @if not unitless($base) {
        $base: unit-strip($base);
    }
  
    @return ($pxval / $base) * 1rem;
}
  
// Unit stripper (per above)
@function unit-strip($value) {
    @return ($value / ($value * 0 + 1));
}
