// Custom font stack
@font-face {
    font-family: "Pero";
    src: url(~assets/fonts/pero-regular-webfont.eot);
    src: url(~assets/fonts/pero-regular-webfont.eot?#iefix) format('embedded-opentype'),
        url(~assets/fonts/pero-regular-webfont.woff2) format('woff2'),
        url(~assets/fonts/pero-regular-webfont.woff) format('woff'),
        url(~assets/fonts/pero-regular-webfont.ttf) format('truetype'),
        url(~assets/fonts/pero-regular-webfont.svg#pero) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Pero";
    src: url(~assets/fonts/pero-bold-webfont.eot);
    src: url(~assets/fonts/pero-bold-webfont.eot?#iefix) format('embedded-opentype'),
        url(~assets/fonts/pero-bold-webfont.woff2) format('woff2'),
        url(~assets/fonts/pero-bold-webfont.woff) format('woff'),
        url(~assets/fonts/pero-bold-webfont.ttf) format('truetype'),
        url(~assets/fonts/pero-bold-webfont.svg#pero) format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Pero";
    src: url(~assets/fonts/pero-semibold-webfont.eot);
    src: url(~assets/fonts/pero-semibold-webfont.eot?#iefix) format('embedded-opentype'),
        url(~assets/fonts/pero-semibold-webfont.woff2) format('woff2'),
        url(~assets/fonts/pero-semibold-webfont.woff) format('woff'),
        url(~assets/fonts/pero-semibold-webfont.ttf) format('truetype'),
        url(~assets/fonts/pero-semibold-webfont.svg#peros) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Pero";
    src: url(~assets/fonts/pero-light-webfont.eot);
    src: url(~assets/fonts/pero-light-webfont.eot?#iefix) format('embedded-opentype'),
        url(~assets/fonts/pero-light-webfont.woff2) format('woff2'),
        url(~assets/fonts/pero-light-webfont.woff) format('woff'),
        url(~assets/fonts/pero-light-webfont.ttf) format('truetype'),
        url(~assets/fonts/pero-light-webfont.svg#pero) format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Pero";
    src: url(~assets/fonts/pero-semilight-webfont.eot);
    src: url(~assets/fonts/pero-semilight-webfont.eot?#iefix) format('embedded-opentype'),
        url(~assets/fonts/pero-semilight-webfont.woff2) format('woff2'),
        url(~assets/fonts/pero-semilight-webfont.woff) format('woff'),
        url(~assets/fonts/pero-semilight-webfont.ttf) format('truetype'),
        url(~assets/fonts/pero-semilight-webfont.svg#pero) format('svg');
    font-weight: 300;
    font-style: normal;
}

.font-reset-sans {
    font-family: $fontSans !important;
}

.font-reset-custom {
    font-family: $fontCustom !important;
}
