body {

    .ui-widget,
    .ui-widget .ui-widget {
        font-family: $fontFamily;
        text-decoration: none;
    }

    .ui-widget-content {
        background-color: $contentBgColor;
        border: 1px solid $contentBorderColor;
    }

    .ui-widget-header {
        background-color: $primaryColor;
        color: $primaryTextColor;
        border: 1px solid $primaryColor;
    }

    .ui-state-active,
    .ui-state-highlight {
        background-color: $accentColor;
        color: $accentTextColor;
    }

    .ui-widget:disabled,
    .ui-state-disabled {
        opacity: .35;
        filter: Alpha(Opacity=35);
        background-image: none;
        cursor: auto !important;
    }

    .ui-corner-all {
        @include border-radius($borderRadius);
    }

    .ui-corner-top {
        @include border-radius-top($borderRadius);
    }

    .ui-corner-bottom {
        @include border-radius-bottom($borderRadius);
    }

    .ui-corner-left {
        @include border-radius-left($borderRadius);
    }

    .ui-corner-right {
        @include border-radius-right($borderRadius);
    }

    .ui-widget-overlay {
        background-color: $overlayMaskBgColor;
        @include opacity(.8);
    }

    a {
        color: $primaryColor;
    }

    .grey-icon {
        color: #777777;
    }

    .evo-color-icon {
        color: #00b5e2;
    }
}