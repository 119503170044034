// Root elements
html {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    background-color: $bodyBgColor;
    height: 100%;

    .layout-container {
        position: relative;
        max-width: 90em;
        margin: auto;
    }

    .flex-container {
        display: flex;

        &.center-content {
            justify-content: center;
        }
    }

    .flexible-container {
        flex-direction: row;
        flex: 1;
    }
}

hr {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $lightGray4;
}

// Main container
#app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h1 {
        margin: 2rem;
        text-transform: uppercase;
    }

    .main-container {
        flex: 1;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        overflow: hidden;
    }

    .primary-content,
    .secondary-content {
        background-color: $brandWhite;
        overflow: auto;
        flex: 1;
        position: relative;

        & > div:not(.auth-container) {
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .secondary-content {
        h3 {
            margin-bottom: 0;
        }
    }

    .view-content {
        padding: 0 0.5rem 1.5rem 0.5rem;
        height: 100%;
    }

    .view-title {
        display: inline-block;
        margin-left: 1rem;
        padding-bottom: 1rem;
        position: relative;
        font-weight: bold;
        text-transform: uppercase;

        &::after {
            display: block;
            position: absolute;
            content: '';
            width: 3rem;
            height: 0;
            border-bottom: 3px solid $brandBlue;
            left: 0;
            bottom: 0;
        }
    }

    .page-loading {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .spinner-inline {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;

        .ui-progress-spinner {
            width: 100%;
            height: 100%;
        }
    }

    .spinner-button {
        top: 0.4rem;
    }

    [data-loading] {
        position: relative;
    }

    [data-loading="false"] {
        .spinner-container {
            display: none;
        }
    }

    [data-loading="true"] {
        .spinner-container {
            display: flex;
            position: absolute;
        }

        & > *:not(.spinner-container) {
            opacity: 0.3;
        }
    }
}
