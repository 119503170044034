// Front container
.auth-container {
    background: $brandBlack;
    min-height: 100%;
    padding: 2rem;
    color: $brandWhite;

    .input-field.input-text,
    .input-field.input-password {
        margin: 0 0 0.5em;

        label {
            transition: none;
            font-family: $fontCustom;
            font-size: 0.9em;
            color: $brandBlue;
            margin: 0;
        }

        .ui-inputtext {
            background: $brandWhite;
            border-radius: $borderRadius;
            border: 0;
            margin: 0.2em 0;
            padding: 1em;
            width: 100%;
            font-size: 1em;
            line-height: 1;
        }
    }

    .input-checkbox {
        label {
            color: $brandWhite;
        }

        .ui-chkbox .ui-chkbox-box {
            border-color: $brandWhite;
        }
    }

    .auth-utils {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        input {
            margin: 0.5em;
        }
    }
}

// Evo logo
.evo-logo-container {
    height: 8rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
  
    div.evo-logo {
        @include svg-image('~assets/logo.svg', 90%);
        flex: 0 0 15rem;
        background-color: $brandBlack;
    }
}

// Forgot password link
.password-recovery-link {
    margin-top: 0.625rem;
    
    a {
        text-decoration: none;
        border-bottom: 1px solid $brandBlue;

        &:hover {
            color: $brandWhite;
            border-color: $brandWhite;
        }
    }
}
