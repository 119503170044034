#edit-promotion-modal {
    h4 {
        margin-top: 0;
    }
}

// #add-promotion-form {
//     margin: 0 -1.5rem;
// }

#apply-promo-code-form {
    display: flex;
    align-items: center;
    min-width: 20rem;
    max-width: 25rem;
}

#marketing-notes {
    padding: 1rem;
    margin-top: 2rem;
}

// Field widths
.info-promotion-search_region {
    flex: 2;
}
