// Car zoomer
@keyframes car-zoom {
    0% {
        opacity: 0;
        transform: scale(0.7) rotateY(-35deg) rotateY(-35deg) translateZ(450px);
    }

    10% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: scale(0.8) rotateY(0deg) rotateY(0deg) translateZ(450px);
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: scale(0.7) rotateY(35deg) rotateY(35deg) translateZ(450px);
    }
}

// Basic fade
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Animate placeholder pseudo element
@keyframes placeholder-loading {
    0% {
        transform: translateX(0%);
    }
    
    99% {
        transform: translateX(5.75rem);
    }
}